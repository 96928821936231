export default [
    {
        path: '/user-info',
        name: 'user-info',
        component: () => import('@/views/user-info/UseType.vue'),
        meta: { auth: true }
    },
    {
        path: '/user-info/activity',
        name: 'user-info.activity',
        component: () => import('@/views/user-info/Activity.vue'),
        meta: { auth: true }
    },
    {
        path: '/user-info/industry',
        name: 'user-info.industry',
        component: () => import('@/views/user-info/Industry.vue'),
        meta: { auth: true }
    },
    {
        path: '/user-info/organization',
        name: 'user-info.organization',
        component: () => import('@/views/user-info/Organization.vue'),
        meta: { auth: true }
    },
    {
        path: '/user-info/team',
        name: 'user-info.team',
        component: () => import('@/views/user-info/Team.vue'),
        meta: { auth: true }
    },
    {
        path: '/user-info/reference',
        name: 'user-info.reference',
        component: () => import('@/views/user-info/Reference.vue'),
        meta: { auth: true }
    },
]