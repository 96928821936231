import Vue from 'vue'
import VueRouter from 'vue-router'
import publicRoutes from './public.routes'
import adminRoutes from './admin.routes'
import userInfoRoutes from "./userInfoForm.routes";
import dashboardRoutes from './dashboard'

import store from '@/store';
// import middlewarePipeline from "./middleware/middlewarePipeline";

Vue.use(VueRouter)

const routes = [
    ...publicRoutes,
    ...adminRoutes,
    ...dashboardRoutes,
    ...userInfoRoutes,
]

const DEFAULT_TITLE = 'Voiceform';

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
})


function allowNotVerified(to){
    if(['dashboard.account',
        'dashboard.help_center',
        'dashboard.billing',
        'dashboard.upgrade',
        'dashboard.checkout',
        'verify-email',
        'user-info',
        'user-info.activity',
        'user-info.industry',
        'user-info.organization',
        'user-info.team',
        'user-info.reference'
     ].includes(to.name)){
        return true
    }

    return false
}

router.beforeEach(async (to, from, next) => {
    // prevent nagivation/redirect when there is an ongoing put request that is modifying the survey
    if ((from.name === 'dashboard.voiceform.show' || from.name === 'dashboard.voiceform.show.question') && store.state.survey.ongoingPutRequest) {
        return false;
    }

    store.dispatch('sidebar/setRoute', { to, from });

    if(to.meta && to.meta.auth === true && (!store.state || !store.state.auth || !store.state.auth.user)){
        await store.dispatch('auth/setUser')

        // init app store

        store.dispatch('teams/fetchMemberships')
        store.dispatch('billing/fetchSubscription')
        store.dispatch('billing/loadPricePlans')
        store.dispatch('payment/loadPaymentMethods')
        store.dispatch('billing/fetchUsage')
    }
    if(window.heap && store.state && store.state.auth && store.state.auth.user){
        window.heap.identify(store.state.auth.user.id)
        window.heap.addUserProperties(store.state.auth.user)
    }

    // redirect to email verification
    if(
        to.meta &&
        to.meta.auth === true &&
        !allowNotVerified(to) &&
        store.state &&
        store.state.auth &&
        store.state.auth.user &&
        !store.state.auth.user.email_verified_at
    ){
        next({name: 'dashboard.account'})
    }else{
        next();
    } 
        
});

router.afterEach((to) => {
    // send page view event to cio
    if(window._cio){
        window._cio.page(window?.location?.href);
    }
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE;
    });
});

// TODO use to parameter after upgrading vue router to version 4 and vue to version 3
router.onError((error) => {
    if (/ChunkLoadError:.*failed./i.test(error.message)) {
        window.location.reload();
    }
    else if (/Loading.*chunk.*failed./i.test(error.message)) {
        window.location.reload();
    }
});

export default router
