import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth';

// Get a Firestore instance
const app = firebase
  .initializeApp({
    apiKey: process.env.VUE_APP_FS_API_KEY,
    authDomain: process.env.VUE_APP_FS_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FS_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FS_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FS_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FS_APP_ID,
  })

const db = app.firestore()
const auth = app.auth()

export { db, auth}

// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { Timestamp, GeoPoint } = firebase.firestore
export { Timestamp, GeoPoint }

const defaultFetcherOptions = {completion:0, startAfter: null, limit: 5}

const allowedCompletionValues = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export const partialResponsesFetcher = async (url, startAfter=null) => {
  // eslint-disable-next-line no-useless-catch
  try {
    // Parsing the URL to get surveyId and optional query parameters
    const urlParts = url.split("/");  // Splitting the URL by "/"
    const surveyId = urlParts[1]; // Getting the surveyId from the correct segment

    const urlSearchParams = new URLSearchParams(urlParts[2].split("?")[1]); // Parsing the query parameters
    
    // Assigning default values and then overriding with query parameters if present
    const completion = parseInt(urlSearchParams.get("completion") || defaultFetcherOptions.completion, 10);
    const limit = parseInt(urlSearchParams.get("limit") || defaultFetcherOptions.limit, 10);
    const q = urlSearchParams.get("q") || '';
    const teamId = urlSearchParams.get("teamId") || '';

    // need to include only those partial responses that are not submitted and are older than 5 minutes
    let query = db.collection('partial-responses').where('formId', '==', surveyId).where('teamId', '==', teamId).where('submitted', '==', false);
    

    if (completion && typeof completion === 'number' && completion > 0) {
      const startIndex = allowedCompletionValues.indexOf(completion);
      if (startIndex !== -1) {
        const valuesToFilter = allowedCompletionValues.slice(startIndex);
        query = query.where('roundedCompletion', 'in', valuesToFilter);
      }
    }

    if(q) {
      query = query.orderBy("metadata.email");
      const startAtValue = q;
      const endAtValue = q + '\uf8ff';
      query = query.where('metadata.email', '>=', startAtValue).where('metadata.email', '<', endAtValue)
    }

    // Ensure we always have an "orderBy" for "createdAt" before "startAfter"
    query = query.orderBy("createdAt", "desc");
    if (startAfter) {
      query = query.startAfter(startAfter);
    }
    

    const snapshot = await query.limit(limit).get();

    return snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    throw error;
  }
};