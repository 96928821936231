

<template>
    <svg version="1.1" id="svg4107" xml:space="preserve" width="682.66669" height="682.66669"
        viewBox="0 0 682.66669 682.66669" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg">
        <defs id="defs4111">
            <clipPath clipPathUnits="userSpaceOnUse" id="clipPath4121">
                <path d="M 0,512 H 512 V 0 H 0 Z" id="path4119" />
            </clipPath>
        </defs>
        <g id="g4113" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
            <g id="g4115">
                <g id="g4117" clip-path="url(#clipPath4121)">
                    <g id="g4123" transform="translate(90.1548,292.7695)">
                        <path class="svg-icon"
                            d="m 0,0 c 20.861,10.997 44.623,17.231 69.844,17.231 82.843,0 150,-67.157 150,-150 0,-82.842 -67.157,-150 -150,-150 -82.843,0 -150,67.158 -150,150 0,25.218 6.231,48.98 17.227,69.837"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4125" />
                    </g>
                    <g id="g4127" transform="translate(197.4756,104.7822)">
                        <path class="svg-icon" d="M 0,0 C -9.94,9.939 -23.42,15.523 -37.477,15.523 -51.533,15.523 -65.014,9.939 -74.953,0"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4129" />
                    </g>
                    <g id="g4131" transform="translate(116.9771,193.6543)">
                        <path class="svg-icon"
                            d="m 0,0 c -2.13,-7.948 -7.33,-14.725 -14.457,-18.839 -7.126,-4.114 -15.594,-5.229 -23.542,-3.099"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4133" />
                    </g>
                    <g id="g4135" transform="translate(241.019,171.7158)">
                        <path class="svg-icon" d="M 0,0 C -7.949,-2.13 -16.417,-1.015 -23.543,3.1 -30.669,7.214 -35.87,13.99 -38,21.938"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4137" />
                    </g>
                    <g id="g4139" transform="translate(303.6177,210.0205)">
                        <path class="svg-icon"
                            d="m 0,0 c 15.579,-5.31 31.924,-8.018 48.383,-8.018 82.843,0 150,67.158 150,150 0,82.843 -67.157,150 -150,150 -82.843,0 -150,-67.157 -150,-150 0,-16.459 2.708,-32.804 8.017,-48.382"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4141" />
                    </g>
                    <g id="g4143" transform="translate(389.4771,312.3076)">
                        <path class="svg-icon" d="m 0,0 c -9.939,-9.94 -23.42,-15.523 -37.477,-15.523 -14.056,0 -27.537,5.583 -37.476,15.523"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4145" />
                    </g>
                    <g id="g4147" transform="translate(314.1895,366.209)">
                        <path class="svg-icon" d="M 0,0 C -5.818,5.818 -13.71,9.087 -21.938,9.087 -30.167,9.087 -38.059,5.818 -43.877,0"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4149" />
                    </g>
                    <g id="g4151" transform="translate(433.688,366.209)">
                        <path class="svg-icon" d="M 0,0 C -5.818,5.818 -13.71,9.087 -21.938,9.087 -30.167,9.087 -38.059,5.818 -43.877,0"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:22.926;stroke-dasharray:none;stroke-opacity:1"
                            id="path4153" />
                    </g>
                    <g id="g4155" transform="translate(53.9272,266.0615)">
                        <path class="svg-icon" d="M 0,0 0.005,0.005"
                            style="fill:none;stroke-width:20;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:2.613;stroke-dasharray:none;stroke-opacity:1"
                            id="path4157" />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
export default {
    props: ['color']
}
</script>

<style lang="scss" scoped>
.svg-icon {
    stroke: currentColor
}
</style>
