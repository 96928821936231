import axios from 'axios'

const externalAPIInstance = axios.create({
	timeout: 8000,
	headers: {
		Accept: 'application/json',
	},
});
export default {
    validateZip(zipCode){
        return externalAPIInstance(`https://zip.org31415.dev/us/${zipCode}`)
    },
    validatePhoneNumber(phone){

        return externalAPIInstance.post('https://validations.org31415.dev/api/validations/ipqs/phone', {
            phone: phone.replace('+', '')
        })
    },
    validateEmail(email){
        return externalAPIInstance.post('https://validations.org31415.dev/api/validations/ipqs/email', {
            email
        })
    },

    getIp(){
        return externalAPIInstance.get('https://api.ipify.org/?format=json')
    }
}