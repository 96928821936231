import form from './form'

export default [
    {
        path: '/dashboard/upgrade',
        name: 'dashboard.upgrade',
        component: () => import('@/views/upgrade/Upgrade.vue'),
        meta: { auth: true, text: 'Upgrade' }
    },
    {
        path: '/dashboard/account',
        name: 'dashboard.account',
        component: () => import('@/views/dashboard/account/Account.vue'),
        meta: {
            auth: true,
            text: 'Account',
        }
    },
    {
        path: '/dashboard/billing',
        name: 'dashboard.billing',
        component: () => import('@/views/billing/Billing.vue'),
        meta: { auth: true, text: 'Billing' }
    },
    {
        path: '/dashboard/checkout/:planId',
        name: 'dashboard.checkout',
        component: () => import('@/views/checkout/Checkout.vue'),
        meta: { auth: true, text: 'Checkout' },
        props: (route) => {
            const planId = Number.parseInt(route.params.planId, 10)
            return { planId }
        }
    },
    // do not move above to avoid overriding
    {
        path: '/dashboard/:teamId?',
        name: 'dashboard.index',
        component: () => import('@/views/home/Home.vue'),
        meta: {
            auth: true,
            text: 'Home',
        },
    },
    ...form,
]


