// English
export default{
    form: {
        startSurveyButton: "Let's start",
        nextButton: "Next",
        submitAnswerButton: "Submit Answer",
        continueButton: "Continue",
        finishButton: "Finish",
        headerPercentageCompletion: 'completed',
        headerQuestionsCompleted: 'questions',
        headerDefaultErrorMessage: 'Ups. Something went wrong.',
        brandingPoweredBy: 'Powered by',
        brandingPromoWhySurvey: 'Why survey when you can <b>{product}</b>?',
        brandingPromoGetStarted: 'Get started today!',
        submissionPending: "Submitting your voiceform. Please don't close your browser window.",
        submissionCanCloseWindow: "Your voiceform has been successfully submitted. You can close the window now 👍",
        mediaAttachmentOverlayCloseButton: "Close",
        expiredFormHeader: "Thanks for stopping by!",
        expiredFormMessage: "It looks like this survey has expired. Please contact the voiceform creator to let them know.",
        notPublishedFormHeader: "The voiceform is not published!",
        notPublishedFormMessage: "It looks like this survey has not been published. Please contact the voiceform creator to let them know.",
        notPublishedFormRetryButton: "Try Again",
        errorFormHeader: "Oops!",
        errorFormMessage: "Something may have gone wrong. Please try again later.",
        errorFormRetryButton: "Try Again",
        emptyFormHeader: "Thanks for stopping by!",
        emptyFormMessage: "Oops! It seems like this form is currently empty with no questions or fields to fill out. We apologize for the inconvenience. To rectify this, kindly reach out to the creator of this form and inform them about the issue. Thank you for your understanding and cooperation!",

        restoreFormHeader: "Looks like you have a form in progress!",
        restoreFormMessage: "Would you like to pick up where you left off?",
        restoreFormButton: "Continue Form",
        restoreFormButtonNew: "Start Over",

        audioPermissionHeader: "Would you like to answer with voice?",
        audioPermissionMessage: "Speaking will make responding quick and easy.",
        audioPermissionAcceptButton: "Yes, I want to",
        audioPermissionDenyButton: "No, thank you",
        audioPermissionErrorHeader: "Microphone is not available",
        audioPermissionErrorMessage: "Please check your browser permissions to enable access to microphone.",
        audioPermissionErrorButton: "Okay",
        audioRecorderFailedUpload: "Failed to upload recording",
        silenceDetectorError: "Can't hear you. Please check your microphone preferences. Tip: It might be your external mic.",
        audioRecorderButtonRecord: "Hit Record",
        audioRecorderButtonRecordMore: "Record More",
        voiceResponseSelectionMessage: "Select how you want to answer...",
        voiceResponseSelectionOrDivider: "or",
        voiceResponseTextInputPlaceholder: "Type answer",
        voiceResponseTextInputCharacterCounterMinMessage: "Please enter at least {min} characters",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} characters (minimum {min} characters)",
        voiceResponseTextInputCharacterValidationMessage: "{count} characters (minimum {min} characters)",

        // checkbox
        checkboxValidationTooFew: "You must select at least {min} option(s)",
        checkboxValidationTooMany: "You must select no more than {max} option(s)",
        checkboxNoneOfTheAboveOption: "None of the above",
        checkboxInvalidAnswer: "Invalid Answer.",

        // datepicker
        datePickerPlaceholder: "Select date",

        // dropdown
        // email
        emailLabel: "Email",
        emailInvalid:"Invalid e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Camera",
        fileUploadPluginNameCameraVideo: "Record video",
        fileUploadPluginScreenCast: "Screen Cast",
        fileUploadVideoImportFilesDefault: 'Record or upload a video via:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Select a video recording option:',
        fileUploadVideoImportFilesNoCamera: 'Hit a button to screen record a video',
        fileUploadVideoImportFilesNoScreenCast: 'Hit a button to record a video',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'No options available to record a video',

        // matrix
        matrixValidationMessage: "The answer is valid.",
        matrixRow: "Row",
        matrixColumn: "Column",
        matrixRowRequired: "The row {rowTitle} is required.",
        matrixRadioGroup: "Radio group",
        matrixCheckboxGroup: "Checkbox group",
        matrixGroupRequired: "For the row {rowTitle}. {type} {groupTitle} is required.",
        matrixColumnRequired: "For the row {rowTitle}. The column {columnTitle} is required.",
        matrixColumnInvalid: "For the row {rowTitle}. The column {columnTitle} is invalid.",
        matrixRequired: "Required.",
        matrixNumericMustBeNumber: "Must be a number.",
        matrixNumericMustBeGreaterThenMin: "Must be greater than {min}.",
        matrixNumericMustBeLessThenMax: "Must be less than {max}.",
        matrixNumericMustBeInteger: "Must be an integer.",
        matrixNumericInvalidNumber: "Invalid number.",

        // name
        nameLabel: "Name",
        nameInvalid: "Specify first and last name.",

        // nps
        npsNotLikely: "Not likely at all",
        npsExtremelyLikely: "Extremely Likely",

        // numeric input
        numericInputRequired: "Required.",
        numericInputMustBeNumber: "Must be a number.",
        numericInputMustBeGreaterThenMin: "Must be greater than {min}.",
        numericInputMustBeLessThenMax: "Must be less than {max}.",
        numericInputMustBeInteger: "Must be an integer.",
        numericInputInvalidNumber: "Invalid number.",
        numericInputPlaceholder: "Type number",

        // phone
        phoneInvalid: "The phone number is invalid.",
        phoneCountrySelectorLabel: 'Country code',
        phoneCountrySelectorError: 'Choose country',
        phoneNumberLabel: 'Phone number',
        phoneExample: 'Example:',

        // boolean
        booleanYesLabel: "Yes",
        booleanNoLabel: "No",

        //questionStep
        questionStepAudioQuestionLabel: "Audio Question",

        // errors
        invalidPhoneNumber: "{phone} is invalid phone number.",
        invalidEmail: "{email} is invalid email address.",
        questionIsRequired: "The question is required.",
        answerIsNotValid: "The answer is not valid.",
        unfinishedProbingDialogError: "Please finish the dialogue.",
        cannotResumePartialResponse: "Cannot resume partial response.",
        failedToSubmitForm: "Failed to submit form. Please check your internet connection and try again.",

        //language picker
        searchLanguage: "Search language",
    }
}