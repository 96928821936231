import axios from 'axios'
export default {
    listMemberships(){
        return axios.get('/api/memberships')
    },

    storeActiveMembership(membershipId){
        return axios.get(`/api/users/set-active-membership/${membershipId}`)
    },
    answerInvite(membershipId, data){
        return axios.post(`/api/memberships/${membershipId}/answer-invite`, data)
    },
    removeMembership(membershipId){
        return axios.delete(`/api/memberships/${membershipId}`)
    },
}
