import UserInfoApi from "../api/UserInfoApi";

const state = {
    useType: null,
    activity: null,
    industry: null,
    organizationSize: null,
    team: null,
    reference: null,
};

const getters = {
    useType: state => state.useType,
    activity: state => state.activity,
    industry: state => state.industry,
    organizationSize: state => state.organizationSize,
    team: state => state.team,
    reference: state => state.reference,
};

const mutations = {
    setUseType(state, useType) {
        state.useType = useType
    },
    setActivity(state, activity) {
        state.activity = activity
    },
    setIndustry(state, industry) {
        state.industry = industry
    },
    setOrganizationSize(state, organizationSize) {
        state.organizationSize = organizationSize
    },
    setTeam(state, team) {
        state.team = team
    },
    setReference(state, reference) {
        state.reference = reference
    },
};

const actions = {
    async updateUserInfo({state, commit}) {
        const user_info = {
            use_type: state.useType,
            activity: state.activity,
            industry: state.industry,
            org_size: state.organizationSize,
            team: state.team,
            reference: state.reference, 
        }
        const requiredFieldsValid = !!(user_info.activity && user_info.reference && user_info.use_type);
        const conditionalFields = !!(user_info.industry && user_info.org_size && user_info.team)
        const conditionalFieldsValid = user_info.use_type === "work" ? conditionalFields : !conditionalFields
        
        if(!requiredFieldsValid || !conditionalFieldsValid) {
            commit(
                'showSnackBar',
                {
                    color: "error",
                    timeout: 5000,
                    text: "Some fields were missing. Please try again."
                },
                {
                    root: true
                }
            );
            return;
        }
        const res = await UserInfoApi.updateUserInfo(user_info);
        if(res && res.data && res.data.user_info_updated) {
            commit('auth/setUser', res.data.user_info, { root: true });
            commit(
                'showSnackBar',
                {
                    color: "success",
                    timeout: 5000,
                    text: "User information updated."
                },
                {
                    root: true
                }
            );
        } else {
            commit(
                'showSnackBar',
                {
                    color: "error",
                    timeout: 5000,
                    text: "Oops something went wrong. Please try again later."
                },
                {
                    root: true
                }
            );
        }
        return res;
    }
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}