const voiceformWidgetTypes = {
    'side-tab': {
        'data-vf-btn-label': 'Feedback',
        'data-vf-btn-color': '#0066FF',
        'data-vf-btn-style': 'filled',
        'data-vf-btn-size': 'medium',
        'data-mode': 'side-tab',
        'data-hash': 'ah1dtsTumkEqUPEl',
        'src': 'https://cdn.voiceform.com/widgets/next/widgets.umd.js'
    },
    'slider': {
        'data-vf-btn-label': 'Feedback',
        'data-vf-btn-color': '#0066FF',
        'data-vf-btn-style': 'text',
        'data-vf-btn-size': 'medium',
        'data-vf-btn-border-radius': '4px',
        'data-vf-btn-fonts-size': '16px',
        'data-vf-btn-icon': 'https://cdn.voiceform.com/app/2484fadf-26b7-4a3c-8409-755e6830445a/images/JX9Vm7VqVBcqUEutpdAgYcSj8FccVZts3U4JHp80.png',
        'data-vf-btn-icon-only': 'false',
        'data-mode': 'slider',
        'data-hash': 'ah1dtsTumkEqUPEl',
        'src': 'https://cdn.voiceform.com/widgets/next/widgets.umd.js'
    }
}

export function initCalendly(){
    const calendlyScript = document.getElementById('calendly-script');
    if(calendlyScript){
        calendlyScript.parentNode.removeChild(calendlyScript)
    }
    const URL = 'https://assets.calendly.com/assets/external/widget.js'
    let script = document.createElement('script')
    script.setAttribute('src', URL)
    script.setAttribute('async', true)
    script.setAttribute('id', 'calendly-script')
    document.head.appendChild(script)

}

export function initGlobalTag(){
    const URL = 'https://www.googletagmanager.com/gtag/js?id=AW-10927426629'
    let script = document.createElement('script')
    script.setAttribute('src', URL)
    script.setAttribute('async', true)
    document.head.appendChild(script)

    script = document.createElement('script')
    script.innerText="window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'AW-10927426629');"
    document.head.appendChild(script)
}

export function zapier(){
    // Load JS
    const script = document.createElement("script");
    script.type = "module";
    script.src = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js";
    document.head.appendChild(script);
    
    // Load CSS
    const stylesheet = document.createElement("link");
    stylesheet.rel = "stylesheet";
    stylesheet.href = "https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css";
    document.head.appendChild(stylesheet);
}

export function voiceform(type) {
    // remove previously attached vf widgets
    if (document.querySelector('#voiceform-widget-loader')) {
        document.querySelector('#voiceform-widget-loader').remove();
    }
    const script = document.createElement('script');
    const attributes = voiceformWidgetTypes[type];
    for (const attr in attributes) {
        script.setAttribute(attr, attributes[attr]);
    }
    script.setAttribute('id', 'voiceform-widget-loader');
    document.head.appendChild(script);
}

export function loadInboundsScripts(){

    // xxTrustedFormCertUrl
    if (document.querySelector('#xxTrusted')) {
        document.querySelector('#xxTrusted').remove();
    }
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'xxTrusted')
    script.innerText= `(function() {var field = 'xxTrustedFormCertUrl';var provideReferrer = false;var invertFieldSensitivity = false;var tf = document.createElement('script');tf.type = 'text/javascript'; tf.async = true;tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +'://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random() + '&invert_field_sensitivity=' + invertFieldSensitivity;var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s);})();`
    document.head.appendChild(script);

    if (document.querySelector('#LeadiDscript')) {
        document.querySelector('#LeadiDscript').remove();
    }

    // LeadiD
    const leadi = document.createElement('script');
    leadi.setAttribute('type', 'text/javascript');
    leadi.setAttribute('id', 'LeadiDscript')
    leadi.innerText = `(function() {var s = document.createElement('script');s.id = 'LeadiDscript_campaign';s.type = 'text/javascript';s.async = true;s.src = '//create.lidstatic.com/campaign/81fbb680-9259-4ba4-e775-c65da8a336eb.js?snippet_version=2';var LeadiDscript = document.getElementById('LeadiDscript');LeadiDscript.parentNode.insertBefore(s, LeadiDscript);})();`
    document.head.appendChild(leadi);


    if (document.querySelector('#leadi-noscript')) {
        document.querySelector('#leadi-noscript').remove();
    }

    const noscript = document.createElement('noscript');
    noscript.setAttribute('id', 'leadi-noscript')
    const img = document.createElement('img')
    img.src = '//create.leadid.com/noscript.gif?lac=66f46e38-526f-74d4-6c54-3c43146c3eba&lck=81fbb680-9259-4ba4-e775-c65da8a336eb&snippet_version=2'
    noscript.appendChild(img)
    document.head.appendChild(noscript);
}

export function loadHotJar(){
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://static.hotjar.com/c/hotjar-' + '2993018' + '.js?sv=' + '6';
    document.head.appendChild(script);

    window.hj = window.hj || function() {
      (window.hj.q = window.hj.q || []).push(arguments);
    };
    window._hjSettings = { hjid: 2993018, hjsv: 6 };
}
