import {
    uploadLogo, update, store, show, destroy, duplicate, duplicateQuestion,
    changeTypeOfQuestion, toggleLikeAnswer, updateAudioAnswerWords, transcribeAnswer
} from "../api/survey";
import router from '@/router'
import WebhooksApi from "../api/WebhooksApi";
import { IS, IS_NOT } from "../configs/operators";
import { variableType } from "../configs/questionTypes";

const state = () => ({
    survey: null,
    loadingSurveys: false,
    ongoingPutRequest: false,
    webhooks: null,
    originalQuestion: null,
    defaultSurvey: {
        id: null,
        version: 'v2',
        name: "",
        use_type: null,
        title: "Welcome",
        goodbye_message: "Goodbye",
        privacy_text: '',
        top_left_file_path: '',
        top_left_file_url: '',
        welcome_page_file_path: '',
        welcome_page_file_url: '',
        language: 'en-US',
        ends_at: null,
        information_to_collect: {
            age: false,
            email: false,
            first_name: false,
            gender: false,
            household_income: false,
            last_name: false,
            location: false,
            phone_number: false,
        },
        settings: {
            is_progressbar_hidden: false,
            is_nav_arrows_hidden: false,
            percentage_progress: false,
            localization: {
                start_button_label: 'Let\'s start',
                next: 'Next',
                back: 'Back',
                finish: 'Finish',
                record: 'Record',
                stop: 'Stop',
                launch: "Launch me",
                question_error_message: "The question is required.",
            },
        },
        background_color: '',
        questions: [],
        style: {
            color: '#000000',
            button: {
                color: '#FFFFFF',
                background: '#000000'
            },
            background: '#FFFFFF'
        },
    },
    stepTypes: [
        { title: "Date Picker", value: "date-picker", icon: "calendar", group: "Basic Components" },
        { title: "Name", value: "name", icon: "profile", group: "Personal Information" },
        { title: "Email", value: "email", icon: "email", group: "Personal Information" },
        { title: "Phone Number", value: "phone-number", icon: "mdi-phone", group: "Personal Information" },
        { title: "Voice Response", value: "voice-response", icon: "microphone", group: "Voice" },
        { title: "Audio Question", value: "voice-question", icon: "account-voice", group: "Voice" },
        { title: "Dropdown", value: "dropdown", icon: "polygon", group: "Basic Components" },
        { title: "Yes / No", value: "yesno", icon: "yesno", group: "Basic Components" },
        { title: "Rating", value: "rating", icon: "star", group: "Basic Components" },
        { title: "Checkbox", value: "checkbox", icon: "checkbox-marked-outline", group: "Basic Components" },
        { title: "Custom Field", value: "custom-field", icon: "pen", group: "Basic Components" },
        { title: "Multiple Choice", value: "multiple-choice", icon: "radiobox-marked", group: "Basic Components" },
        { title: "Net Promoter Score", value: "net-promoter-score", icon: "speedometer", group: "Basic Components" },
        { title: "Description Slide", value: "description-slide", icon: "image-text", group: "Basic Components" },
    ],
    question: null,
    defaultQuestion: {
        multiple_choice_items: [],
        media_attachments: [],
        text: null,
        type: null,
        _id: null,
        order: null,
        style: {
            background: null,
            color: null,
        }
    },
    activeStep: {
        questionType: null,
        questionId: null,
        step: null,
    },
    audioPlayer: {
        currentAudioAnswer: null,
        audioList: [],
        isPlaying: false
    }
})

const getters = {
    defaultSurvey: state => {
        return state.defaultSurvey;
    },
    activeStep: state => {
        return state.activeStep
    },
    question: state => {
        return state.question
    },
    originalQuestion: state => {
        return state.originalQuestion
    },
    mediaAttachments: state => {
        return state.question && state.question.media_attachments ? state.question.media_attachments.sort((a, b) => a.order - b.order) : [] 
    },
    survey: state => {
        return state.survey
    },
    surveys: state => {
        if (state.surveys) {
            state.surveys = state.surveys.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());
        }
        return state.surveys
    },
    loadingSurveys: state => {
        return state.loadingSurveys
    },
    ongoingPutRequest: state => {
        return state.ongoingPutRequest
    },
    isApiSurvey: state => {
        return state.surveys && state.surveys.type === 'api-form'
    },
    stepTypes: state => {
        return state.stepTypes
    },
    audioPlayer: state => {
        return state.audioPlayer
    },
    webhooks: state => {
        return state.webhooks
    }
}
const mutations = {
    setDefaultSurvey(state, payload) {
        state.survey = payload || state.defaultSurvey
    },
    setDefaultQuestion(state) {
        state.question = state.defaultQuestion
    },
    removeCurrentQuestion(state) {
        state.question = null;
    },
    addQuestionToSurvey(state) {
        state.question._id = Math.random().toString(36).substring(2, 12)
        state.question.order = state.survey.questions.length
        state.survey.questions.push(state.question)
    },
    updateQuestionInSurvey(state, index) {
        state.survey.questions[index] = state.question
    },
    destroyQuestionInSurvey(state, id) {
        state.survey.questions = state.survey.questions.filter(e => e.id != id)
    },
    setLoadingSurveys(state, loadingSurveys){
        state.loadingSurveys = loadingSurveys
    },
    setActiveStep(state, step) {
        state.activeStep = step
    },
    addSurvey(state, survey) {
        // push it to the back because we are sorting by ascending creation time
        state.surveys.push(survey)
    },
    setSurvey(state, survey) {
        // syncing order with an question array
        survey.questions.forEach((question, i) => { question.order = i })



        if (!survey.style) {
            survey.style = Object.assign({}, state.defaultSurvey.style)
        } else {
            Object.keys(state.defaultSurvey.style).forEach(key => {
                if (!survey.style[key]) {
                    survey.style[key] = state.defaultSurvey.style[key]
                }
            })
        }
        if (!survey.settings.localization) {
            survey.settings.localization = Object.assign({}, state.defaultSurvey.settings.localization)
        } else {
            Object.keys(state.defaultSurvey.settings.localization).forEach(key => {
                if (!survey.settings.localization[key]) {
                    survey.settings.localization[key] = state.defaultSurvey.settings.localization[key]
                }
            })
        }

        if (survey.background_color) {
            survey.style.background = survey.background_color
            survey.background_color = null
        }

        state.survey = survey
    },
    setQuestion(state, question) {
        if (question && !question.style) {
            question.style = Object.assign({}, state.defaultQuestion.style)
        } else {
            Object.keys(state.defaultQuestion.style).forEach(key => {
                if (!question.style[key]) {
                    question.style[key] = state.defaultQuestion.style[key]
                }
            })
        }

        if (question && !question.type) {
            if (question.multiple_choice_items.length > 0) {
                question.type = 'multiple-choice'
            } else {
                question.type = 'voice-response'
            }
        } else if (question && question.type == 'plain') {
            question.type = 'voice-response'
        }
        state.question = question
    },
    destroySurvey(state, id) {
        state.surveys = state.surveys.filter(survey => survey.id != id)
    },
    setLogo(state, data) {
        state.survey.top_left_file_path = data.path
        state.survey.top_left_file_url = data.url
    },
    setWelcomeImage(state, data) {
        state.survey.welcome_page_file_path = data.path
        state.survey.welcome_page_file_url = data.url
    },
    setInformation(state, { name, value }) {
        state.survey.information_to_collect[name] = value
    },
    addChoiceToQuestion(state) {
        state.question.multiple_choice_items.push({ value: "" })
    },
    addMediaAttachmentToQuestion(state, attachment){
        if(state.question.media_attachments){
            state.question.media_attachments.push(attachment)
        }else{
            state.question.media_attachments = [attachment]
        }
    },
    updateQuestionDescription(state, description){
        state.question.description = description
    },
    deleteMediaAttachmentFromQuestion(state, attachment){
        const index = state.question.media_attachments && state.question.media_attachments.findIndex(item => item.url === attachment.url)
        if(typeof index === 'number' && index > -1){
            state.question.media_attachments.splice(index, 1)
        }
    },
    deleteChoiceFromQuestion(state, index) {
        state.question.multiple_choice_items.splice(index, 1)
    },
    setChoices(state, choices) {
        state.question.multiple_choice_items = choices
    },
    deleteChoices(state) {
        state.question.multiple_choice_items = []
    },
    setSettings(state, newSettings){
        state.survey.settings = {...state.survey.settings, ...newSettings}
    },
    setAudioPlayback(state, { answer, answerList }) {
        state.audioPlayer = {
            ...state.audioPlayer,
            currentAudioAnswer: answer,
            audioList: answerList
        };
    },
    removeAudioFromLikedList(state, { answerId, newAudioList }) {
        const idx = state.audioPlayer.audioList.findIndex(ans => ans.id === answerId);
        if (idx === -1) return;

        state.audioPlayer.audioList = newAudioList;
        if (state.audioPlayer.audioList.length > idx) {
            state.audioPlayer.currentAudioAnswer = state.audioPlayer.audioList[idx];
        } else if (state.audioPlayer.audioList.length > 0) {
            state.audioPlayer.currentAudioAnswer = state.audioPlayer.audioList[idx - 1];
        } else {
            state.audioPlayer = {
                currentAudioAnswer: null,
                audioList: [],
                isPlaying: false
            };
        }
    },
    resetAudioPlayback(state) {
        state.audioPlayer = {
            currentAudioAnswer: null,
            audioList: [],
            isPlaying: false
        };
    },
    setCurrentAudio(state, offset) {
        const oldIndex = state.audioPlayer.audioList.findIndex(e => e.id === state.audioPlayer.currentAudioAnswer.id);
        const newIndex = oldIndex + offset;
        state.audioPlayer.currentAudioAnswer = state.audioPlayer.audioList[newIndex];
    },
    setAudioPlayerPlaying(state, isPlaying) {
        state.audioPlayer.isPlaying = isPlaying;
    },
    setAnswerLike(state, { answerId, setLike, questionId }) {
        const question = state.survey.questions.find(x => x.id === questionId);
        const answer = question.answers.find(x => x.id === answerId);
        answer.liked = setLike;
    },
    setWebhooks(state, webhooks){
        state.webhooks = webhooks
    },
    setAnswer(state, {questionId, resultId, answerId, answerData}){
        const question = state.survey?.questions?.find(question=> question.id===questionId)
        if(question){
            for (let index = 0; index < question.answers.length; index++) {
                const answer = question.answers[index];
                if(answer.id === answerId){
                    
                    // making the change reactive
                    const newQuestionAnswersArray = [...question.answers]
                    newQuestionAnswersArray[index] = answerData
                    question.answers = [...newQuestionAnswersArray]
                    break;
                }
            }
        }

        const result = state.survey?.results?.find(result=> result.id===resultId)
        if(result){
            for (let index = 0; index < result.answers.length; index++) {
                const answer = result.answers[index];
                if(answer.id === answerId){
                    // making the change reactive
                    const newResultAnswersArray = [...result.answers]
                    newResultAnswersArray[index] = answerData
                    result.answers = [...newResultAnswersArray]
                    break;
                }
            }
        }
    }
}

const actions = {
    async createSurvey({state, commit, dispatch}){
        const res = await store(state.survey)
        if (res && res.status == 200) {
            commit('setDefaultSurvey')
            commit('setSurvey', res.data)
            dispatch('setSurveyToLS')
        }
        return res
    },
    async duplicateQuestion({state, commit}, question) {
        try {
            const { data, status } = await duplicateQuestion(state.survey.id, question.id);
            if (status == 200) {
                commit('setSurvey', data)
                if(data && data.questions) {
                    return "success";
                }
            } else {
                throw new Error('Error duplicating question');
            }
        } catch (error) {
            console.log('duplicateQuestion', error);
        }
    },
    async changeQuestionType({ state, commit }, { question, newType }) {
        try {
            const { data, status } = await changeTypeOfQuestion(state.survey.id, question.id, newType);
            if (status == 200) {
                commit('setSurvey', data.surveyData);
                if (data && data.surveyData && data.surveyData.questions) {
                    return data.newQuestionId;
                }
            } else {
                throw new Error('Error changing question type');
            }
        } catch (err) {
            console.log('changeQuestionType', err);
        }
    },
    async createQuestion({ state, commit }, question) {
        try {
            commit('setQuestion', question)
            commit('addQuestionToSurvey')
            const { data, status } = await update(state.survey)
            if (status == 200) {
                commit('setSurvey', data)
                if(data && data.questions){
                    return data.questions[data.questions.length-1].id
                }
            }
            throw new Error('cannot create new question', data, status)
        } catch (error) {
            console.error('createQuestion',error)
        }
    },
    async setSurvey({ commit, dispatch, rootGetters }, surveyId) {
        try {
            let res = null
            if(router && router.currentRoute && router.currentRoute.params.surveyId){
                res = await show(router.currentRoute.params.surveyId)
            }else if (surveyId) {
                res = await show(surveyId)
            }
            if (res && res.status == 200) {
                commit('setDefaultSurvey')
                commit('setSurvey', res.data)
                dispatch('setSurveyToLS')

                // sync active membership id with backend
                if(rootGetters['teams/activeMembership'] && rootGetters['teams/activeMembership'].team && rootGetters['teams/activeMembership'].team.id &&  rootGetters['teams/activeMembership'].team.id !== res.data.team_id){
                    dispatch('auth/setUser', null, {root: true})
                }
            }
        } catch (error) {
            console.error('setSurvey',error)
        }
    },

    async setSurveyById({ commit, dispatch }, surveyId) {
        try {
            const res = await show(surveyId)
            if (res && res.status == 200) {
                commit('setDefaultSurvey')
                commit('setSurvey', res.data)
                dispatch('setSurveyToLS')
            }
            return res
        } catch (error) {
            console.error('setSurvey',error)
        }
    },

    async updateSurvey({ commit, state }) {
        state.ongoingPutRequest = true;
        try {
            let { data, status } = await update(state.survey)
            if (status == 200) {
                commit('setSurvey', data)
            }
            state.ongoingPutRequest = false;
            return data
        } catch (error) {
            state.ongoingPutRequest = false;
            console.error(error);
        }
    },

    async destroySurvey({ commit }, survey) {
        await destroy(survey.id)
        commit('destroySurvey', survey.id)
    },
    async duplicateSurvey({ commit }, surveyId) {
        try {
            const response = await duplicate(surveyId)
            if (response.status === 200) {
                commit('addSurvey', response.data);
            }
        } catch (error) {
            console.log('duplicateSurvey error', error);
        }
    },
    async setLogo({ commit, dispatch, state }, file) {
        if (file) {
            await uploadLogo(file, state.survey.hash).then(({ data }) => {
                commit('setLogo', data)
            })
        } else {
            commit('setLogo', { url: null, path: null })
        }
        dispatch('updateSurvey')
    },
    async setWelcomeImage({ commit, dispatch, state }, file) {
        if (file) {
            await uploadLogo(file, state.survey.hash).then(({ data }) => {
                commit('setWelcomeImage', data)
            })
        } else {
            commit('setWelcomeImage', { url: null, path: null })
        }
        dispatch('updateSurvey')
    },
    setSurveyToLS({ state }) {
        localStorage.setItem('survey', state.survey.id);
    },
    setInformation({ commit, state }, { name, value }) {

        let { required, optional } = value
        if (required && !optional) {
            commit('setInformation', { name, value: 'required' })
        } else if (optional) {
            commit('setInformation', { name, value: 'optional' })
        } else {
            commit('setInformation', { name, value: false })
        }
        update(state.survey)
    },

    async fetchWebhooks({commit, state}){
        const surveyId = state && state.survey && state.survey.id || router.currentRoute && router.currentRoute.params.surveyId
        if(surveyId){
            const res = await WebhooksApi.listWebhooks(surveyId)
            if(res.data){
                commit('setWebhooks', res.data)
            }
            return res
        }
    },

    async createWebhook({commit, state}, url){
        const surveyId = state && state.survey && state.survey.id || router.currentRoute && router.currentRoute.params.surveyId
        if(surveyId){
            const res = await WebhooksApi.createWebhook(surveyId, url)
            if(res.data && res.data.status==='saved' && res.data.webhooks){
                commit('setWebhooks', res.data.webhooks)
            }
            return res
        }
    },

    async updateWebhook({commit, state}, {webhookId, data}){
        const surveyId = state && state.survey && state.survey.id || router.currentRoute && router.currentRoute.params.surveyId
        if(surveyId){
            const res = await WebhooksApi.updateWebhook(surveyId, webhookId, data)
            if(res.data && res.data.status==='updated' && res.data.webhooks){
                commit('setWebhooks', res.data.webhooks)
            }
            return res
        }
    },
    async deleteWebhook({commit, state}, webhookId){
        const surveyId = state && state.survey && state.survey.id || router.currentRoute && router.currentRoute.params.surveyId
        if(surveyId){
            const res = await WebhooksApi.deleteWebhook(surveyId, webhookId)
            if(res.data && res.data.status==='deleted' && res.data.webhooks){
                commit('setWebhooks', res.data.webhooks)
            }
            return res
        }
    },

    async updateAnswerWords({commit}, {questionId, resultId, answerId, words}){
        if(answerId){
            const res = await updateAudioAnswerWords(answerId, words)
            if(res.data && res.data.status==='updated' && res.data.answer){
                commit('setAnswer', { questionId, resultId, answerId, answerData: res.data.answer });
                return res
            }
        }
    },

    async regenerateTranscription({commit}, {questionId, resultId, answerId}){
        if(answerId){
            const res = await transcribeAnswer(answerId)
            if(res.data && res.data.answer){
                commit('setAnswer', { questionId, resultId, answerId, answerData: res.data.answer });
                return res
            }
        }
    },
    
    async toggleLikingAnswer({ commit }, { answerId, setLike, questionId }) {
        try {
            commit('setAnswerLike', { answerId, setLike, questionId });
            await toggleLikeAnswer(answerId, setLike);
        } catch (err) {
            // reset to original value
            commit('setAnswerLike', { answerId, setLike: !setLike, questionId });
            console.error(err);
        }
    },

    async checkIfQuestionCanHaveBrokenReference({state}, question){
        const ruleType = variableType(question)

        if(ruleType!=='options'){
            return false
        }

        // check if question has conditions referencing itself
        if(question?.actions && question.actions.length){
            for (let actionIndex = 0; actionIndex < question.actions.length; actionIndex++) {
                const action = question.actions[actionIndex];
                const conditionWithReference = action.conditions.find(
                    condition =>
                        condition?.variable?.question_ref === question.id &&
                        [IS, IS_NOT].includes(condition.op)
                );
                if(conditionWithReference){
                    return true
                }
            }
            question.actions.find(action => action.conditions)
        }

        // check other questions
        for (let questionIndex = 0; questionIndex < state.survey.questions.length; questionIndex++) {
            const questionItem = state.survey.questions[questionIndex];
            // avoid a check on the passed question again
            if(questionItem.id === question.id){
                continue;
            }
            for (let actionIndex = 0; actionIndex < questionItem.actions.length; actionIndex++) {
                const action = questionItem.actions[actionIndex];
                const conditionWithReference = action.conditions.find(
                    condition =>
                        condition?.variable?.question_ref === question.id &&
                        [IS, IS_NOT].includes(condition.op)
                );
                if(conditionWithReference){
                    return true
                }
            }
        }
        
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

