export default [
    {
        path: '/dashboard/voiceform/:surveyId/create',
        name: 'dashboard.voiceform.show',
        redirect: to => {
            return { name: 'dashboard.voiceform.show.question', params: {
                surveyId: to.params.surveyId,
                stepType: 'welcome_screen'
            } }
        },
        props: {
            action: "show"
        },
        meta: {
            auth: true,
            text: 'Voiceform',
        },
    },
    {
        path: '/dashboard/voiceform/templates',
        name: 'dashboard.voiceform.templates',
        component: () => import('@/views/dashboard/voiceform/create/Templates.vue'),
        props: {
            action: "create"
        },
        meta: {
            auth: true,
            text: 'Templates',
        },
    },
    {
        path: '/dashboard/voiceform',
        name: 'dashboard.voiceform',
        redirect: { name: 'dashboard.voiceform.show' },
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/share',
        name: 'dashboard.voiceform.share',
        component: () => import('@/views/dashboard/voiceform/Share.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/question/:stepType?',
        name: 'dashboard.voiceform.show.question',
        component: () => import('@/views/dashboard/voiceform/create/StepProperties.vue'),
        props: {
            action: "show"
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/analyze/individuals/:resultId',
        name: 'dashboard.voiceform.analyze.individual-by-id',
        redirect: to => {
            return { name: 'dashboard.voiceform.results.respondents', params: to.params }
        },
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/results/questions/:questionId?',
        name: 'dashboard.voiceform.results.questions',
        component: () => import('@/views/dashboard/voiceform/results/ByQuestion.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/results/respondents/:resultId?',
        name: 'dashboard.voiceform.results.respondents',
        component: () => import('@/views/dashboard/voiceform/results/ByRespondent.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/results/likes',
        name: 'dashboard.voiceform.results.likes',
        component: () => import('@/views/dashboard/voiceform/results/Likes.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/results/visualizations',
        name: 'dashboard.voiceform.results.visualizations',
        component: () => import('@/views/dashboard/voiceform/results/Visualizations.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/results/insights',
        name: 'dashboard.voiceform.results.insights',
        component: () => import('@/views/dashboard/voiceform/results/Insights.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/results',
        name: 'dashboard.voiceform.results',
        component: () => import('@/views/dashboard/voiceform/results/Insights.vue'),
        meta: {
            auth: true,
        },
        redirect: to => {
            return { name: 'dashboard.voiceform.results.insights', params: to.params }
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/results/partial-responses/:responseId?',
        name: 'dashboard.voiceform.results.partial-responses',
        component: () => import('@/views/dashboard/voiceform/results/PartialResponsesLayout.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/connect',
        name: 'dashboard.voiceform.connect',
        meta: {
            auth: true,
        },
        redirect: to => {
            return { name: 'dashboard.voiceform.connect.integrations', params: to.params }
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/connect/webhooks',
        name: 'dashboard.voiceform.connect.webhooks',
        component: () => import('@/views/dashboard/voiceform/connect/webhooks/Webhooks.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/connect/data-mapping',
        name: 'dashboard.voiceform.connect.data-mapping',
        component: () => import('@/views/dashboard/voiceform/connect/data-mapping/DataMappingActionList.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/connect/data-mapping/create',
        name: 'dashboard.voiceform.connect.data-mapping.create',
        component: () => import('@/views/dashboard/voiceform/connect/data-mapping/DataMappingAction.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/connect/data-mapping/:actionId',
        name: 'dashboard.voiceform.connect.data-mapping.update',
        component: () => import('@/views/dashboard/voiceform/connect/data-mapping/DataMappingAction.vue'),
        meta: {
            auth: true,
        },
    },
    {
        path: '/dashboard/voiceform/:surveyId/connect/integrations',
        name: 'dashboard.voiceform.connect.integrations',
        component: () => import('@/views/dashboard/voiceform/connect/integrations/Integrations.vue'),
        meta: {
            auth: true,
        },
    },

    {
        path: '/dashboard/voiceform/:surveyId/logic',
        name: 'dashboard.voiceform.logic',
        component: () => import('@/views/dashboard/voiceform/create/logic/Logic.vue'),
        meta: {
            auth: true,
        },
    },
]

