import PricePlan from "@/models/PricePlan";
import BillingApi from "../api/BillingApi";

const state = {
    paymentMethod: null,
    defaultPaymentMethod: null,
    paymentMethods: [],
    activePricePlan: {},
};

const getters = {
    paymentMethod: state => {
        if(!state.paymentMethod){
            return state.defaultPaymentMethod;
        }
        return state.paymentMethod
    },
    defaultPaymentMethod: state => {
        return state.defaultPaymentMethod
    },
    paymentMethods: state => {
        return state.paymentMethods
    },
};

const mutations = {
    setPaymentMethod: (state, paymentMethod) => {
        state.paymentMethod = paymentMethod
    },
    setPaymentMethods: (state, paymentMethods) => {
        state.paymentMethods = paymentMethods
    },
    setDefaultPaymentMethod: (state, defaultPaymentMethod) => {
        state.defaultPaymentMethod = defaultPaymentMethod
    },
    setActivePricePlan: (state, activePricePlan) => {
        state.activePricePlan = activePricePlan
    },
};

const actions = {
    async loadPaymentMethods({commit}) {
        let { data } = await BillingApi.getPaymentMethods();
        const paymentMethods = data
        commit('setPaymentMethods', paymentMethods)
        commit('setDefaultPaymentMethod', paymentMethods[0])
    },
    updatePaymentMethod({state, commit }, paymentMethodId) {
        const paymentMethods = state.paymentMethods;
        for (let i = 0; i < paymentMethods.length; i++) {
            if (paymentMethods[i].id == paymentMethodId) {
                commit('setPaymentMethod', paymentMethods[i])
            }
        }
    },
    async updatePricePlan({ commit }, price_plan_id) {
        if(price_plan_id){
            let activePricePlan = await PricePlan.find(price_plan_id);
            commit('setActivePricePlan', activePricePlan)
        }
    },
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}
