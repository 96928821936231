import dayjs from "dayjs"

export const DATE_PICKER = 'date-picker'
export const NAME = 'name'
export const VOICE_RESPONSE = 'voice-response'
export const VOICE_QUESTION = 'voice-question'
export const EMAIL = 'email'
export const PHONE_NUMBER = 'phone-number'
export const ZIP_CODE = 'zip-code'
export const YESNO = 'yesno'
export const RATING = 'rating'
export const CHECKBOX = 'checkbox'
export const MULTIPLE_CHOICE = 'multiple-choice'
export const DROPDOWN = 'dropdown'
export const CUSTOM_FIELD = 'custom-field'
export const NET_PROMOTER_SCORE = 'net-promoter-score'
export const DESCRIPTION_SLIDE = 'description-slide'
export const FILE_UPLOAD = 'file-upload'
export const NUMERIC_INPUT = 'numeric-input'
export const MATRIX = 'matrix'

export const TYPES_ARRAY = [
    VOICE_RESPONSE,
    VOICE_QUESTION,
    DATE_PICKER,
    NAME,
    EMAIL,
    ZIP_CODE,
    PHONE_NUMBER,
    DROPDOWN,
    YESNO,
    RATING,
    CHECKBOX,
    CUSTOM_FIELD,
    MULTIPLE_CHOICE,
    NET_PROMOTER_SCORE,
    DESCRIPTION_SLIDE,
    NUMERIC_INPUT,
    FILE_UPLOAD,
    MATRIX,
];

export const questionIcons = {
    [VOICE_RESPONSE]: 'mdi-microphone',
    [VOICE_QUESTION]: 'mdi-account-voice',
    [DATE_PICKER]: 'mdi-calendar-range',
    [NAME]: 'mdi-account-check',
    [EMAIL]: 'mdi-at',
    [ZIP_CODE]: 'mdi-map-marker-outline',
    [PHONE_NUMBER]: 'mdi-phone',
    [NUMERIC_INPUT]: 'mdi-numeric-0-box-multiple-outline',
    [DROPDOWN]: 'mdi-form-select',
    [YESNO]: 'mdi-toggle-switch',
    [RATING]: 'mdi-star',
    [CHECKBOX]: 'mdi-checkbox-marked',
    [CUSTOM_FIELD]: 'mdi-form-textbox',
    [MULTIPLE_CHOICE]: 'mdi-radiobox-marked',
    [NET_PROMOTER_SCORE]: 'mdi-speedometer',
    [DESCRIPTION_SLIDE]: 'mdi-image-text',
    [FILE_UPLOAD]: 'mdi-file-upload',
    [MATRIX]: 'mdi-table',
}

export const titles = {
    [VOICE_RESPONSE]: 'Voice Response',
    [VOICE_QUESTION]: 'Audio Question',
    [DATE_PICKER]: 'Date Picker',
    [NAME]: 'Name',
    [EMAIL]: 'Email',
    [ZIP_CODE]: 'Zip Code',
    [PHONE_NUMBER]: 'Phone Number',
    [NUMERIC_INPUT]: 'Numeric Input',
    [DROPDOWN]: 'Dropdown Menu',
    [YESNO]: 'Yes/No',
    [RATING]: 'Rating',
    [CHECKBOX]: 'Checkbox List',
    [CUSTOM_FIELD]: 'Text Input',
    [MULTIPLE_CHOICE]: 'Multiple Choice',
    [NET_PROMOTER_SCORE]: 'Net Promoter Score',
    [DESCRIPTION_SLIDE]: 'Description Slide',
    [FILE_UPLOAD]: 'File Upload',
    [MATRIX]: 'Matrix',
}

export default {
    DATE_PICKER,
    NAME,
    VOICE_RESPONSE,
    EMAIL,
    ZIP_CODE,
    PHONE_NUMBER,
    NUMERIC_INPUT,
    YESNO,
    RATING,
    CHECKBOX,
    MULTIPLE_CHOICE,
    DROPDOWN,
    CUSTOM_FIELD,
    VOICE_QUESTION,
    NET_PROMOTER_SCORE,
    DESCRIPTION_SLIDE,
    FILE_UPLOAD,
    MATRIX,
}


export function variableType(question){
    if(question){
        if([NET_PROMOTER_SCORE, RATING, NUMERIC_INPUT].includes(question.type)){
            return 'number'
        }
    
        if([DATE_PICKER].includes(question.type)){
            return 'date'
        }
    
        if([NAME, EMAIL, ZIP_CODE, PHONE_NUMBER, CUSTOM_FIELD].includes(question.type)){
            return 'text'
        }
    
        if([YESNO, MULTIPLE_CHOICE, DROPDOWN].includes(question.type)){
            return 'options'
        }

        if([CHECKBOX].includes(question.type)){
            return 'multiple_options'
        }

        if([MATRIX].includes(question.type)){
            return 'matrix'
        }
    }

    return 'default'
}

export function defaultConditionValue(variableType, question){
    switch (variableType) {
        case 'text':
            return ''
        case 'number':
            return 1
        case 'date':
            return dayjs().format('YYYY-MM-DD')
        case 'options':
            return question.multiple_choice_items && question.multiple_choice_items[0] && question.multiple_choice_items[0].value || null
        case 'multiple_options':
            return question.multiple_choice_items && question.multiple_choice_items[0] && question.multiple_choice_items[0].value || null
        case 'matrix':
            return question.properties && question.properties.rows && question.properties.rows[0] && question.properties.rows[0].id || null
        default:
            return 'answered';
    }
}
