import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors'
import FormIcon from '../assets/img/icons/FormIcon.vue'
import SentimentIcon from '../assets/img/icons/SentimentIcon.vue'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
        values: {
            form: {
                component: FormIcon,
            },
            sentiment: {
                component: SentimentIcon
            }
        }
    },
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: {
                    base: '#0066FF',
                    dark: '#2341E0',
                    light: '#0079FF',
                    background: "linear-gradient(0deg, rgba(0, 102, 255, 0.08), rgba(0, 102, 255, 0.08)), #FFFFFF",
                    border: 'rgba(0, 102, 255, 0.5)',
                    contrastText: '#ffffff',
                    lighten5: '#e6f0ff',
                    lighten4: '#cce0ff',
                    lighten3: '#99c2ff',
                    lighten2: '#66a3ff',
                    lighten1: '#0079FF',
                    darken1: '#2341E0',
                    darken2: '#0047b3',
                    darken3: '#003380',
                    darken4: '#001f4d',
                },
                secondary: '#212121',
                grey: colors.grey,
                accent: '#82B1FF',
                error: {
                    base: '#F44336',
                    dark: '#E31B0C',
                    light: '#F88078',
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #F44336",
                    border: 'rgba(244, 67, 54, 0.5)',
                    textDark: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #F44336',
                    contrastText: '#ffffff',
                    lighten5: '#feeceb',
                    lighten4: '#fcc6c3',
                    lighten3: '#faa19b',
                    lighten2: '#f77b72',
                    lighten1: '#F88078',
                    darken1: '#E31B0C',
                    darken2: '#c3352b',
                    darken3: '#922820',
                    darken4: '#621a16',
                },
                info: '#2196F3',
                success: {
                    base: '#4CAF50',
                    dark: '#3B873E',
                    light: '#7BC67E',
                    background: "linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50",
                    border: 'rgba(76, 175, 80, 0.5)',
                    textDark: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), #4CAF50',
                    contrastText: '#ffffff',
                    lighten5: '#edf7ed',
                    lighten4: '#c9e7ca',
                    lighten3: '#a6d7a7',
                    lighten2: '#82c784',
                    lighten1: '#7BC67E',
                    darken1: '#3B873E',
                    darken2: '#357a37',
                    darken3: '#2e692f',
                    darken4: '#1e4620',
                },
                warning: '#FF9800',
                deepPurple: colors.deepPurple,
                deepOrange: colors.deepOrange,
                yellow: colors.yellow,
            },
            // TODO: add colors when the dark theme is supported
            dark: {
                primary: '#fd5312',
                secondary: '#424242',
                lightGrey: '#f2f2f2',
                accent: '#fb9f1e',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107'
            },
        },
    },
});
