import Vue from 'vue';
import dayjs from 'dayjs';

var duration = require('dayjs/plugin/duration')
dayjs.extend(duration)

var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
});