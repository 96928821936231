import axios from 'axios'
import { createAPIAccessToken, listAPIAccessTokens, revokeAPIAccessTokens } from '../api/survey';
import MembershipsApi from '../api/MembershipsApi'

const state = {
    token: null,
    user: null,
    accessTokens: null,
};

const getters = {
    user: state => {
        return state.user
    },
    isPaidAccount: state => {
        return !!(state.user && state.user.price_plan && state.user.price_plan.price)
    },
    accessTokens: state => {
        return state.accessTokens
    },
    features (state, getters, rootState, rootGetters) {
        return (
                rootGetters['teams/activeMembership'] && 
                rootGetters['teams/activeMembership'].team &&
                rootGetters['teams/activeMembership'].team.features
            ) || state.user && state.user.features
    },
    showLimitedTimeOffer(state){

        return !!(state?.user?.limited_time_offer && !state.user.price_plan_id && new Date(state.user.limited_time_offer).getTime() > new Date().getTime())
    }
};

const mutations = {
    setUser: (state, user) => {
        state.user = user
    },
    setTokens: (state, tokens) => {
        state.accessTokens = tokens
    },
};

const actions = {
    async setUser({ commit }) {
        try {
            // TODO: it shouldn't be based on local storage
            const token = localStorage.getItem('auth_token_default') || sessionStorage.getItem('auth_token_default')
            if(token){
                const { data } = await axios.get('/api/users/auth')
                commit('setUser', data.data)
                return data;
            }
        /* eslint-disable no-console */
        } catch (error) {
            console.info('');
        }
    },
    async setApiTokens({commit}){
        const { data } = await listAPIAccessTokens()
        commit('setTokens', data)
    },

    async createApiToken({dispatch}, label){
        const res = await createAPIAccessToken(label)
        if(res.status === 200 && res.data && res.data.token){
            dispatch('setApiTokens');
            return res.data.token;
        }
    },

    async revokeApiToken({dispatch}, tokenId){
        const res = await revokeAPIAccessTokens(tokenId)
        if(res.status == 200){
            dispatch('setApiTokens');
        }
    },
    async updateActiveMembership({commit}, membershipId){
        const res = await MembershipsApi.storeActiveMembership(membershipId)
        if(res.status == 200 && res.data && res.data.active_membership_id_updated){
            commit('setUser', res.data.user)
        }
        return res
    }
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}
