import axios from 'axios'
export default {
    addNewAction(surveyId, questionId, actionData){
        return axios.post(`/api/surveys/${surveyId}/questions/${questionId}/actions`, {
            ...actionData
        })
    },
    updateAction(surveyId, questionId, actionId, actionData){
        return axios.put(`/api/surveys/${surveyId}/questions/${questionId}/actions/${actionId}`, {
            ...actionData
        })
    },

    deleteAction(surveyId, questionId, actionId){
        return axios.delete(`/api/surveys/${surveyId}/questions/${questionId}/actions/${actionId}`)
    },
    listQuestions(surveyId){
        return axios.get(`/api/logic/${surveyId}/questions`)
    }
}