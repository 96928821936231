<template>
    <v-app>
        <router-view/>
        <saas-snackbar />
    </v-app>
</template>

<script>
    import SaasSnackbar from "./components/SaasSnackbar";
    import StripeApi from "./api/StripeApi";
    import store from '@/store';
    import { initGlobalTag, loadHotJar, zapier } from './utils/scriptLoader';
    import { Crisp } from "crisp-sdk-web";

    Crisp.configure('1250d56c-a101-409a-8f1a-3c3f80eb9f9d', {
        autoload: false
    });

    export default {
        name: 'App',
        computed: {
            layout() {
                return `${(this.$route.meta.layout || 'empty')}-layout`
            }
        },
        components: {
            SaasSnackbar,
        },

        methods: {
            initAppData(){
                store.dispatch('teams/fetchMemberships')
                store.dispatch('billing/fetchSubscription')
                store.dispatch('billing/loadPricePlans')
                store.dispatch('payment/loadPaymentMethods')
                store.dispatch('billing/fetchUsage')
            }
        },
        async mounted(){
            if(!window.location.pathname.includes('/to/')){
                // eslint-disable-next-line
                window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
                window.heap.load(process.env.VUE_APP_HEAP_APP_ID);
            }

            
            
            const auth = await store.dispatch('auth/setUser')


            initGlobalTag()
            zapier()


            if(process.env.VUE_APP_APP_ENV === 'production' && !(window?.location?.pathname?.includes('/to/'))){
                loadHotJar()
            }

            if(auth && auth.data){
                StripeApi.includeStripe()
                // this.initAppData()
                if(window.heap){
                    window.heap.identify(auth.data.id)
                    window.heap.addUserProperties(auth.data)
                    window.heap.addUserProperties(auth.data.user_info)
                }

                const data = {
                    user_id: auth.data.id,
                    user_email: auth.data.email,
                    user_name: auth.data.first_name? `${auth.data.first_name} ${auth.data.last_name}`: auth.data.name,
                    plan_id: auth.data.price_plan_id,
                    price_plan_name: auth.data.price_plan?.name,
                    price_plan_interval: auth.data.price_plan?.interval,
                    is_appsumo: auth.data.coupons_count > 0,
                }

                const cioData = {
                    ...auth.data
                }


                if(auth.data.limited_time_offer){
                    data.limited_time_offer = auth.data.limited_time_offer
                    data.limited_time_offer_expired = auth.data.limited_time_offer ? new Date().getTime() > new Date(auth.data.limited_time_offer_expired).getTime() : null

                    // pass boolean variable to cio
                    cioData.limited_time_offer_expired = data.limited_time_offer_expired
                }



                // eslint-disable-next-line
                _cio && _cio.identify(cioData);

                Crisp.user.setEmail(auth.data.email);
                Crisp.user.setNickname(auth.data.first_name? `${auth.data.first_name} ${auth.data.last_name}`: auth.data.name);

                Crisp.session.setData(data);

                if(window.hj){
                    window.hj('identify', auth.data.id, {
                        ...data
                    });
                }
            }
        }
    };
</script>
<style type="text/css">
    #nprogress .bar {
        height: 2px !important;
        background-color: var(--primary);
    }
    #nprogress .peg {
        box-shadow: unset !important;
    }
    #nprogress .spinner-icon {
        display: none;
    }
</style>
