import axios from 'axios'
export default {
    loadIntentToken() {
        return new Promise((resolve, reject) => {
            axios.get('/api/billing/setup-intent')
                .then(response => resolve(response.data))
                .catch(e => reject(e));
        })
    },

    getPaymentMethods(){
        return axios.get('/api/billing/payment-methods')
    },

    updateSubscription(paymentMethodStripeId, planStripeId) {
        return axios.put('/api/billing/subscription', {
            plan: planStripeId,
            payment: paymentMethodStripeId,
        })
    },

    getSubscription(){
        return axios.get('/api/billing/subscription')
    },

    getUsage(){
        return axios.get('/api/billing/usage')
    },

    cancelSubscription() {
        return axios.get('/api/billing/cancel-subscription')
    },

    resumeSubscription(){
        return axios.get('/api/billing/resume-subscription')
    },

    addPaymentMethod(payment_method){
        return axios.post('/api/billing/payments', {
            payment_method
        })
    },
    removePaymentMethod(methodStripeId) {
        return new Promise((resolve, reject) => {
            axios.post('/api/billing/remove-payment', {
                id: methodStripeId
            }).then(res => resolve(res.data)).catch(e => reject(e));
        })
    },

    previewInvoice(stripe_plan_id){
        return axios.post('/api/billing/preview-invoice', {
            stripe_plan_id
        })
    },

    startFreeTrial(){
        return axios.get('/api/billing/start-free-trial');
    },

    addAppsumoCode(code) {
        return axios.post('/api/appsumo/add-appsumo-code', { code })
    },
    previewResponsesSync(planId){
        return axios.post('/api/billing/preview-responses-sync', {plan: planId})
    }

}
