
export default {
  namespaced: true,
  state: {
    recording: false,
    loading: false,
    streamInitLoading:false,
    permissionDrawer: false,
    permissions: null,
    permissionsError: false, 
    blockedPermission: false,
    uploadingRecording: false
  },
  getters: {
    recording: state => state.recording,
    loading: state => state.loading,
    streamInitLoading: state => state.streamInitLoading,
    permissionDrawer: state => state.permissionDrawer,
    permissions: state => state.permissions,
    permissionsError: state => state.permissionsError,
    uploadingRecording: state => state.uploadingRecording
  },
  actions: {
    // recording
    startRecording({commit}){
      commit('setRecording', true)
    },
    stopRecording({commit}){
      commit('setRecording', false)
    },
    toggleRecording({commit, state}){
      commit('setRecording', !state.recording)
    },
    // loading
    startLoading({commit}){
      commit('setLoading', true)
    },
    stopLoading({commit}){
      commit('setLoading', false)
    },
    toggleLoading({commit, state}){
      commit('setLoading', !state.loading)
    },
    // streamInitLoading
    startStreamInitLoading({commit}){
      commit('setStreamInitLoading', true)
    },
    stopStreamInitLoading({commit}){
      commit('setStreamInitLoading', false)
    },
    toggleStreamInitLoading({commit, state}){
      commit('setStreamInitLoading', !state.streamInitLoading)
    },
    async updatePermissions({commit}, permissionValue){
      const permission = !!permissionValue
      commit('setPermission', permission)
      if(permission){
        try {
          // will trigger browser microphone permissions 
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
          const tracks = stream.getTracks();
          tracks.forEach(function(track) {
              track.stop();
          });
          commit('setPermissionDrawer', false)
          return stream
        } catch (error) {
          commit('setPermission', false)
          return commit('setPermissionsError', true)
        }
      }
      
      commit('setPermissionDrawer', false)
    }
  },
  mutations: {
    setRecording(state, payload){
      state.recording = payload
    },
    setLoading(state, payload){
      state.loading = payload
    },
    setStreamInitLoading(state, payload){
      state.streamInitLoading = payload
    },
    setPermissionDrawer(state, payload){
      state.permissionDrawer = !!payload
    },
    setPermission(state, payload){
      state.permissions = !!payload
    },
    setPermissionsError(state, payload){
      state.permissionsError = !!payload
    },
    setUploadingRecording(state, payload){
      state.uploadingRecording = !!payload
    }
  }
}