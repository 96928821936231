import Vue from 'vue'
import VueKonva from 'vue-konva';
import 'simple-syntax-highlighter/dist/sshpre.css'
import './scss/custom-code-highlight.css'
import './scss/json-view.scss'
import './css/app.scss';
import './scss/base.scss';

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import './plugins/dayjs';
import './plugins/vue-auth'
import 'axios-progress-bar/dist/nprogress.css'
import "drawflow/dist/drawflow.min.css";
import "./scss/logic-editor.scss";

import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { loadProgressBar } from 'axios-progress-bar'
import _ from 'lodash';
import VueSocialSharing from 'vue-social-sharing'
import AudioVisual from 'vue-audio-visual';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import VueSkeletonLoader from 'skeleton-loader-vue';
import { createCioTrackerMixin } from "./mixins/cio-tracker";
import { firestorePlugin } from 'vuefire'
import { uuid } from 'vue-uuid';
import Cookies from 'js-cookie'


import VueGtag from "vue-gtag";
import i18n from './i18n';

import 'core-js/actual/array/group';

try {
  if (!window.localStorage) {
    throw new Error('localStorage not available');
  }
} catch (err) {
  Object.defineProperty(window, 'localStorage', {
    value: {
      clear: () => { },
      getItem: () => { },
      key: () => { },
      removeItem: () => { },
      setItem: () => { }
    }
  });
}

Object.defineProperty(Vue.prototype, '$_', { value: _ });
if(process.env.VUE_APP_APP_ENV === 'production' || process.env.VUE_APP_APP_ENV === 'staging'){
  Sentry.init({
      Vue,
      dsn: "https://0dc1892e57fc4aaaada5484459d790c7@o999759.ingest.sentry.io/6086377",
      integrations: [
        new Integrations.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: ["app.voiceform.com", /^\//],
        }),
      ],
      environment: process.env.VUE_APP_APP_ENV,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
}

try{
  window.pubUUID = Cookies.get('uuid')
  if(!window.pubUUID){
      window.pubUUID = uuid.v4()
      Cookies.set('uuid', window.pubUUID, { expires: 365 }) // 1 year expiration
  }
}catch(e){
  console.error('Error in initializing pubUUID', e);
}

Vue.config.productionTip = false;

Vue.config.ignoredElements = ["zapier-app-directory"];
require('./bootstrap');

if(window && window.location && window.location.pathname){
  if(!window.location.pathname.match(/\/to\/\S+/g)){
    loadProgressBar()
  }
}

Vue.use(VueSocialSharing);
Vue.use(AudioVisual);
Vue.use(VueKonva);
Vue.use(VueSkeletonLoader);

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS }
}, router);

Vue.use(firestorePlugin)

Vue.mixin(createCioTrackerMixin());

new Vue({
    i18n,
    router,
    store,
    vuetify,
    data: {},
    render: h => h(App)
}).$mount('#app')
