// src/i18n.js
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import dayjs from 'dayjs';
import en from './lang/en';



Vue.use(VueI18n);

const messages = {
  en,    // English
  // es,    // Spanish
  // fr: {},    // French
  // zh: {},    // Chinese
  // tl: {},    // Tagalog
  // ar: {},    // Arabic
  // vi: {},    // Vietnamese
  // pt: {},    // Portuguese
  // de: {},    // German
  // ko: {},    // Korean
  // ru: {},    // Russian
  // hi: {},    // Hindi
  // it: {},    // Italian
  // ur: {},    // Urdu
  // fa: {},    // Persian
  // pl: {},    // Polish
  // el: {},    // Greek
  // ja: {},    // Japanese
  // pa: {},    // Punjabi
  // uk: {},    // Ukrainian
  // gu: {},    // Gujarati
  // he: {},    // Hebrew
  // bn: {},    // Bengali
  // ta: {},    // Tamil
  // tr: {},    // Turkish
  // sw: {},    // Swahili
  // nl: {},    // Dutch
  // bs: {},    // Bosnian
  // hr: {},    // Croatian
  // hy: {},    // Armenian
  // ro: {},    // Romanian
  // sr: {},    // Serbian
  // cs: {},    // Czech
  // sv: {},    // Swedish
  // no: {},    // Norwegian
  // da: {},    // Danish
  // hu: {},    // Hungarian
  // sk: {},    // Slovak
  // sl: {},    // Slovenian
  // mk: {},    // Macedonian
  // lv: {},    // Latvian
  // lt: {},    // Lithuanian
  // et: {},    // Estonian
  // af: {},    // Afrikaans
  // gl: {},    // Galician
  // cy: {},    // Welsh
  // is: {},    // Icelandic
  // fi: {},    // Finnish
  // mi: {},    // Maori
  // kn: {},    // Kannada
  // ne: {},    // Nepali
  // kk: {},    // Kazakh
  // mr: {},    // Marathi
  // az: {},    // Azerbaijani
  // ms: {},    // Malay
  // th: {}     // Thai
};

const loadedLanguages = ['en'] // our default language that is preloaded

const i18n = new VueI18n({
  locale: 'en', // default language
  fallbackLocale: 'en', // fallback language
  messages,
});

function setI18nLanguage (lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  dayjs.locale(lang);
  return lang
}

// Function to lazy load Day.js locale dynamically
function loadDayjsLocale(lang) {
  // Handle exceptions where the locale doesn't follow the 2-letter code convention
  const exceptions = {
    zh: 'zh-cn',
    pt: 'pt-br',
    pa: 'pa-in',
    hy: 'hy-am',
    tl: 'tl-ph',
  };

  // Check if the language is in the exceptions object, otherwise load the 2-letter code
  const localeToLoad = exceptions[lang] || lang;

  return import(`dayjs/locale/${localeToLoad}`);
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/i18n/lang/${lang}.js`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default);
      loadedLanguages.push(lang);

      // Load the corresponding Day.js locale
      return loadDayjsLocale(lang).then(() => setI18nLanguage(lang));
    }
  );
}


export default i18n;