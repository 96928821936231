import routes from '@/router/dashboard'
const state = () => ({
    menu: null,
    route: null,
    parentRoute: null,
    prevRoute: null,
})

const getters = {
    menu: state => {
        return state.menu
    },
    route: state => {
        return state.route
    },
    parentRoute: state => {
        return state.parentRoute
    },
    prevRoute: state => {
        return state.prevRoute
    },
}
const mutations = {
    setMenu(state, menu) {
        state.menu = menu
    },
    setRoute(state, route) {
        state.route = route
    },
    setParentRoute(state, route) {
        state.parentRoute = route
    },
    setPrevRoute(state, route) {
        state.prevRoute = route
    }

}

const actions = {
    async setRoute({ commit, dispatch }, { to, from }) {
        await commit('setRoute', to)
        await commit('setParentRoute', from)
        await commit('setPrevRoute', from)
        dispatch('setMenu')
    },
    async setMenu({ state, commit, dispatch }) {

        let children = []
        if (state.route.meta.parent) {
            children = await routes.filter(route => {
                if (typeof route.meta.parent == typeof {}) {
                    return route.meta.parent.includes(state.route.name)
                }
                return route.meta.parent == state.route.name
            })
            if (children.length > 0) {
                commit('setMenu', children)
            } else {
                let siblings = await routes.filter(route => route.meta.parent == state.route.meta.parent)
                commit('setMenu', siblings)
            }
        } else {
            children = await routes.filter(route => {
                return route.meta.parent == 'dashboard.index'
            })
            commit('setMenu', children)
        }

        if (state.route.meta.parent != undefined) {
            dispatch('setParent', state.route)
        } else {
            commit('setParentRoute', null)
        }
    },
    async setParent({ commit, dispatch }, route) {
        let parent = await routes.find(item => {
            return item.name == route.meta.parent
        })
        if (parent) {
            if (parent.redirect) {
                dispatch('setParent', parent)
            }

            commit('setParentRoute', parent)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

