
export const IS_EQUAL_TO = 'is-equal-to';
export const IS_NOT_EQUAL_TO = 'is-not-equal-to';
export const IS_LOWER_THAN = 'is-lower-than';
export const IS_GREATER_THAN = 'is-greater-than'
export const IS_LOWER_OR_EQUALS_TO = 'is-lower-or-equals-to';
export const IS_GREATER_OR_EQUALS_TO = 'is-greater-or-equals-to';
export const IS_ON = 'is-on';
export const IS_NOT_ON = 'is-not-on';
export const IS_BEFORE = 'is-before';
export const IS_AFTER = 'is-after';
export const IS_BEFORE_OR_ON = 'is-before-or-on';
export const IS_AFTER_OR_ON ='is-after-or-on';
export const BEGINS_WITH= 'begins-with';
export const NOT_BEGINS_WITH = 'not-begins-with';
export const ENDS_WITH = 'ends-with';
export const NOT_ENDS_WITH = 'not-ends-with';
export const CONTAINS = 'contains';
export const DOES_NOT_CONTAIN = 'does-not-contain';
export const IS = 'is';
export const IS_NOT = 'is-not';
export const NUMBER_OF_SELECTED_OPTIONS_IS = 'number-of-selected-options-is';
export const NUMBER_OF_SELECTED_OPTIONS_IS_NOT = 'number-of-selected-options-is-not';
export const NUMBER_OF_SELECTED_OPTIONS_IS_LOWER_THAN = 'number-of-selected-options-is-lower-than';
export const NUMBER_OF_SELECTED_OPTIONS_IS_GREATER_THAN = 'number-of-selected-options-is-greater-than';
export const ANSWERED = 'answered';
export const NOT_ANSWERED = 'not-answered';

const defaultOperators = [
    {name: 'answered', value: ANSWERED},
    {name: 'not answered', value: NOT_ANSWERED},
]

export default {
    number: [
        { name: 'is equal to', value: IS_EQUAL_TO},
        { name: 'is not equal to', value: IS_NOT_EQUAL_TO},
        { name: 'is lower than', value: IS_LOWER_THAN},
        { name: 'is greater than', value: IS_GREATER_THAN},
        { name: 'is lower or equal to', value: IS_LOWER_OR_EQUALS_TO},
        { name: 'is greater or equal to', value: IS_GREATER_OR_EQUALS_TO},
        ...defaultOperators
    ],
    date: [
        {name: 'is on', value: IS_ON},
        {name: 'is not on', value: IS_NOT_ON},
        {name: 'is before', value: IS_BEFORE},
        {name: 'is after', value: IS_AFTER},
        {name: 'is before or on', value: IS_BEFORE_OR_ON},
        {name: 'is after or on', value: IS_AFTER_OR_ON},
        ...defaultOperators
    ],
    text: [
        {name: 'is equal to', value: IS_EQUAL_TO},
        {name: 'is not equal to', value: IS_NOT_EQUAL_TO},
        {name: 'begins with', value: BEGINS_WITH},
        {name: 'not begins with', value: NOT_BEGINS_WITH},
        {name: 'ends with', value: ENDS_WITH},
        {name: 'not ends with', value: NOT_ENDS_WITH},
        {name: 'contains', value: CONTAINS},
        {name: 'does not contain', value: DOES_NOT_CONTAIN},
        ...defaultOperators
    ],
    options: [
        {name: 'is', value: IS},
        {name: 'is not', value: IS_NOT},
        ...defaultOperators
    ],
    multiple_options: [
        {name: 'is', value: IS},
        {name: 'is not', value: IS_NOT},
        {name: '# of selected options is', value: NUMBER_OF_SELECTED_OPTIONS_IS},
        {name: '# of selected options is not', value: NUMBER_OF_SELECTED_OPTIONS_IS_NOT},
        {name: '# of selected options is lower than', value: NUMBER_OF_SELECTED_OPTIONS_IS_LOWER_THAN},
        {name: '# of selected options is greater than', value: NUMBER_OF_SELECTED_OPTIONS_IS_GREATER_THAN},
        ...defaultOperators
    ],
    default: defaultOperators
}


export function isTruthy(condition, answerVariable){
    try {
        const isMatrixCondition = condition && condition.variable && condition.variable.type === 'matrix'
        let type, value, answer ;
        if(isMatrixCondition && answerVariable){
            const row = answerVariable[condition.variable.row_id]
            const column = row && row[condition.variable.col_id]
            if(!column){
                return false
            }
            const columnType = column && column.type

            value = condition.value

            if(columnType === 'numeric'){
                type = 'number'
            }

            if(columnType === 'dropdown'){
                answer = column.value?.id
            }else if(columnType === 'radio'){
                answer = column.col_id
            }
            else if(Array.isArray(column)){
                answer = column.map(c => c?.col_id)
            }else{
                answer = Array.isArray(column)?column:column.value
            }

        }else{
            type = condition && condition.variable && condition.type
            value = condition.value
            answer = answerVariable
        }

        if(!answer && answer !== 0){
            return condition.op === NOT_ANSWERED? true : false
        }

        if(type === 'date'){
            value = new Date(value).getTime()
            answer = new Date(answer).getTime()
        }

        if(type === 'number'){
            value = Number(value)
            answer = Number(answer)
        }

        if(type === 'text'){
            value = String(value)
            answer = String(answer)
        }

        switch (condition.op) {
            case IS_EQUAL_TO:
                return answer == value
            case IS_NOT_EQUAL_TO:
                return answer != value
            case IS_LOWER_THAN:
                return answer < value
            case IS_GREATER_THAN:
                return answer > value
            case IS_LOWER_OR_EQUALS_TO:
                return answer <= value
            case IS_GREATER_OR_EQUALS_TO:
                return answer >= value
            case IS_ON: 
                return answer === value
            case IS_NOT_ON: 
                return answer !== value
            case IS_BEFORE:
                return answer < value
            case IS_AFTER:
                return answer > value
            case IS_BEFORE_OR_ON:
                return answer <= value
            case IS_AFTER_OR_ON:
                return answer >= value
            case BEGINS_WITH: 
                return answer.startsWith(value)
            case NOT_BEGINS_WITH: 
                return !answer.startsWith(value)
            case ENDS_WITH:
                return answer.endsWith(value)
            case NOT_ENDS_WITH:
                return !answer.endsWith(value)
            case CONTAINS:
                return answer.includes(value)
            case DOES_NOT_CONTAIN:
                return !answer.includes(value)
            case IS:
                if(Array.isArray(answer)){
                    return answer.includes(value)
                }
                return answer == value
            case IS_NOT:
                if(Array.isArray(answer)){
                    return !answer.includes(value)
                }
                return answer != value
            case NUMBER_OF_SELECTED_OPTIONS_IS:
                if(Array.isArray(answer)){
                    return answer.length == value
                }
                return false
            case NUMBER_OF_SELECTED_OPTIONS_IS_NOT:
                if(Array.isArray(answer)){
                    return answer.length != value
                }
                return false
            case NUMBER_OF_SELECTED_OPTIONS_IS_LOWER_THAN:
                if(Array.isArray(answer)){
                    return answer.length < value
                }
                return false
            case NUMBER_OF_SELECTED_OPTIONS_IS_GREATER_THAN:
                if(Array.isArray(answer)){
                    return answer.length > value
                }
                return false
            case ANSWERED:
                return !!answer
            case NOT_ANSWERED:
                return !answer
            default:
                return false;
        }
    } catch (error) {
        return false
    }
}

export function getOperatorSymbol(operator){
    switch (operator) {
        case IS_EQUAL_TO:
            return '='
        case IS_NOT_EQUAL_TO:
            return '≠'
        case IS_LOWER_THAN:
            return '<'
        case IS_GREATER_THAN:
            return '>'
        case IS_LOWER_OR_EQUALS_TO:
            return '≤'
        case IS_GREATER_OR_EQUALS_TO:
            return '≥'
        case IS_ON: 
            return 'on'
        case IS_NOT_ON: 
            return 'not on'
        case IS_BEFORE:
            return 'before'
        case IS_AFTER:
            return 'after'
        case IS_BEFORE_OR_ON:
            return 'by'
        case IS_AFTER_OR_ON:
            return 'until'
        case BEGINS_WITH: 
            return 'begins with'
        case NOT_BEGINS_WITH: 
            return 'not begins with'
        case ENDS_WITH:
            return 'ends with'
        case NOT_ENDS_WITH:
            return 'not ends with'
        case CONTAINS:
            return 'contains'
        case DOES_NOT_CONTAIN:
            return 'excludes'
        case IS:
            return 'is'
        case IS_NOT:
            return 'is not'
        case NUMBER_OF_SELECTED_OPTIONS_IS:
            return 'selected # is'
        case NUMBER_OF_SELECTED_OPTIONS_IS_NOT:
            return 'selected # is not'
        case NUMBER_OF_SELECTED_OPTIONS_IS_LOWER_THAN:
            return 'selected # is <'
        case NUMBER_OF_SELECTED_OPTIONS_IS_GREATER_THAN:
            return 'selected # is >'
        case ANSWERED:
            return 'answered'
        case NOT_ANSWERED:
            return 'not answered'
        default:
            return '';
    }
}

