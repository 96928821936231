import BillingApi from "../api/BillingApi";
import { listPublicPlans } from "../api/plans";

const state = {
    plans: null,
    subscription: null,
    usage: null,
    pricePlan: null
};

const getters = {
    plans: state => state.plans,
    subscription: state => state.subscription,
    usage: state => state.usage,
    pricePlan: state => state.pricePlan
};

const mutations = {
    setPlans(state, plans){
        state.plans = plans
    },
    setSubscription(state, subscription){
        state.subscription = subscription
    },
    setUsage(state, usage){
        state.usage = usage
    },
    setPricePlan(state, pricePlan){
        state.pricePlan = pricePlan
    },
};

const actions = {
    async loadPricePlans({commit}){
        const res = await listPublicPlans()
        if(res.data){
            commit('setPlans', res.data)
        }
    },

    async fetchSubscription({commit}){
        const res = await BillingApi.getSubscription()
        if(res.data && res.data.subscription){
            commit('setSubscription', res.data.subscription)
        }

        if(res.data && res.data.price_plan){
            commit('setPricePlan', res.data.price_plan)
        }

        if(res.data.redirect){
            window.location.href = res.data.redirect;
        }
        return res.data.subscription
    },
    async cancelSubscription({commit, dispatch}){
        const res = await BillingApi.cancelSubscription()
        if(res.data && res.data.subscription){
            commit('setSubscription', res.data.subscription)
        }

        if(res.data && res.data.price_plan){
            commit('setPricePlan', res.data.price_plan)
        }

        dispatch('fetchUsage')
        return res
    },

    async resumeSubscription({commit, dispatch}){
        const res = await BillingApi.resumeSubscription()
        if(res.data && res.data.subscription){
            commit('setSubscription', res.data.subscription)
        }

        if(res.data && res.data.price_plan){
            commit('setPricePlan', res.data.price_plan)
        }

        dispatch('fetchUsage')

        return res
    },

    async updateSubscription({commit, dispatch}, {paymentMethodId, priceId}){
        const res = await BillingApi.updateSubscription(paymentMethodId, priceId)
        if(res.data && res.data.subscription){
            commit('setSubscription', res.data.subscription)
        }

        if(res.data && res.data.price_plan){
            commit('setPricePlan', res.data.price_plan)
        }

        if(res.data && res.data.redirect){
            window.location.href = res.data.redirect;
        }

        dispatch('fetchUsage')
        dispatch('auth/setUser', null, {root: true})

        return res
    },

    async fetchUsage({commit}){
        const res = await BillingApi.getUsage()
        if(res.data){
            commit('setUsage', res.data)
        }

        return res
    },
    async startFreeTrial({dispatch}){
        const res = await BillingApi.startFreeTrial()
        dispatch('fetchUsage')
        dispatch('auth/setUser', null, {root: true})
        return res
    }
};



export default {
    namespaced: true,
    state, getters, mutations, actions
}