import User from '../models/User';
import AdminApi from '@/api/AdminApi';

const state = {
    users: [],
    pricePlans: []
};

const getters = {
    users: state => state.users,
};

const mutations = {
    setUsers(state, users) {
        state.users = users
    },
    setPricePlans(state, pricePlans) {
        state.pricePlans = pricePlans
    }
};

const actions = {
    async fetchUsers({commit}) {
        const fetchedRes = await User.get()
        commit('setUsers', fetchedRes.data)
        return fetchedRes;
    },
    async fetchPricePlans({commit}) {
        const fetchedRes = await AdminApi.getPricePlans()
        if(fetchedRes.data){
            commit('setPricePlans', fetchedRes.data)
        }
    }
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}