import { Model as BaseModel } from 'vue-api-query'
import store from '../store/index'

export default class Model extends BaseModel {

  // Define a base url for a REST API
  baseURL() {
    return '/api'
  }

  // Implement a default request method
  request(config) {
    return this.$http.request(config).catch(e => {
        e && e.response && e.response.data && e.response.data.message && store.commit("showSnackBar", {text: e.response.data.message, color: 'danger', timeout: 3000})
    })
  }
}
