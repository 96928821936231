export function createCioTrackerMixin() {
    return {
      mounted() {
        this.loadCioTracker();
      },
      methods: {
        loadCioTracker() {
          if (typeof window._cio !== 'undefined') return;
  
          window._cio = window._cio || [];
          const a = (f) => {
            return function () {
              // eslint-disable-next-line
              _cio.push([f].concat(Array.prototype.slice.call(arguments, 0)));
            };
          };
          const b = ["load", "identify", "sidentify", "track", "page"];
          for (let c = 0; c < b.length; c++) {
            // eslint-disable-next-line
            _cio[b[c]] = a(b[c]);
          }
  
          const t = document.createElement("script");
          const s = document.getElementsByTagName("script")[0];
          t.async = true;
          t.id = "cio-tracker";
          t.setAttribute("data-site-id", process.env.VUE_APP_IO_SITE_ID);
          t.setAttribute("data-base-url", process.env.VUE_APP_APP_URL);
          t.setAttribute("data-use-array-params", "true");
          t.setAttribute("data-auto-track-page", "true");
          // t.setAttribute("data-in-app-org-id", "undefined");
          t.setAttribute("data-use-in-app", "true");
          t.src = "https://assets.customer.io/assets/track.js";
          s.parentNode.insertBefore(t, s);
        },
      },
    };
  }