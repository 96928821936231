import axios from 'axios'

export const uploadLogo = (file, survey_hash) => {
    let form = new FormData();
    form.append('file', file);
    form.append('directory', 'images');
    form.append('survey_hash', survey_hash)
    return axios.post('/api/upload/image', form);
}

export const uploadMediaFile = async (file, survey_hash) => {
    let form = new FormData();
    form.append('file', file);
    form.append('directory', 'images');
    form.append('survey_hash', survey_hash);
    return await axios.post('/api/upload/image', form);
}

export const uploadAudio = async (formData) => {
    return  await axios.post('/api/upload-audio', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}
export const store = (survey) => {
    return axios.post('/api/surveys', survey);
}
export const update = (survey) => {
    return axios.put('/api/surveys/' + survey.id, survey);
}
export const show = (id) => {
    return axios.get('/api/surveys/' + id);
}
export const index = () => {
    return axios.get('/api/surveys');
}
export const destroy = (id) => {
    return axios.delete('/api/surveys/' + id);
}

export const updateAudioAnswerWords = (answerId, words) => {
    return axios.put(`/api/answers/${answerId}/words`, {words})
}

export const transcribeAnswer = (answerId) => {
    return axios.get(`/api/answers/${answerId}/transcribe`)
}

export const duplicate = async (id) => await axios.get(`/api/surveys/${id}/duplicate`);

export const duplicateQuestion = async (survey_id, question_id) => await axios.get(`/api/surveys/${survey_id}/questions/${question_id}/duplicate`);

export const changeTypeOfQuestion = async (survey_id, question_id, newType) => {
    return await axios.post(`/api/surveys/${survey_id}/questions/${question_id}/changeType`, { newType });
};

export const submitSurvey = async (surveyId, submissionData) => {
   return await axios.post(
        `/api/surveys/${surveyId}/results`,
        submissionData,
        { crossdomain: true }
      );
}

export const updateQuestionStartStatistics = async (surveyHash, questionId) => {
    const storageKey = `start-${surveyHash}-${questionId}`
    try {
        if(!localStorage.getItem(storageKey)){
            const res = await axios.get(
                `/api/questions-public/${surveyHash}/${questionId}/update-statistics?start=1`,
                { crossdomain: true }
                );
                if(res.status === 200){
                    localStorage.setItem(storageKey, true);
                }
            }
        } catch (error) {
            // TODO: add error tracking here
        }
        
        localStorage.setItem(storageKey, true);
}

export const updateQuestionViewStatistics = async (surveyHash, questionId) => {
    const storageKey = `view-${surveyHash}-${questionId}`
    try {
        if(!localStorage.getItem(storageKey)){
            const res = await axios.get(
                `/api/questions-public/${surveyHash}/${questionId}/update-statistics?view=1`,
                { crossdomain: true }
            );
            if(res.status === 200){
                localStorage.setItem(storageKey, true);
            }
        }
     } catch (error) {
         console.error('updateQuestionViewStatistics', error);
     }
}

export const updateSurveyStartStatistics = async (surveyHash) => {
    const storageKey = `start-${surveyHash}`
    try {
        if(!localStorage.getItem(storageKey)){
            const res = await axios.get(
                `/api/surveys-public/${surveyHash}/update-statistics?start=1`,
                { crossdomain: true }
            );
            if(res.status === 200){
                localStorage.setItem(storageKey, true);
            }
        }
    } catch (error) {
        console.error('updateSurveyStartStatistics', error);
    }
}

export const updateSurveyViewStatistics = async (surveyHash) => {
    const storageKey = `view-${surveyHash}`
    try {
        if(!localStorage.getItem(storageKey)){
            const res = await axios.get(
                `/api/surveys-public/${surveyHash}/update-statistics?view=1`,
                { crossdomain: true }
                );
            if(res.status === 200){
                localStorage.setItem(storageKey, true);
            }
        }
    } catch (error) {
        console.error('updateSurveyViewStatistics', error);
    }
}

export const destroyResult = async (id) => {
    try {
        return await axios.delete(`/api/results/${id}`)
    } catch (error) {
        console.error('destroyResult', error);
    }
}

export const createAPIAccessToken = async (label) => {
    try {
        return await axios.post(`/api/public-api-access-tokens`, {label})
    } catch (error) {
        console.error('createAPIAccessToken', error);
    }
}

export const listAPIAccessTokens = async () => {
    try {
        return await axios.get('/api/public-api-access-tokens')
    } catch (error) {
        console.error('listAPIAccessTokens', error);
    }
}

export const revokeAPIAccessTokens = async (tokenId) => {
    try {
        return await axios.delete(`/api/public-api-access-tokens/${tokenId}`)
    } catch (error) {
        console.error('revokeAPIAccessTokens', error);
    }
}

export const exportAllResponsesData = async (surveyId, type) => {
    let response = await axios.get(
        `/api/surveys/${surveyId}/export?type=${type}`,
        {
            responseType: "blob",
        }
    );
    const contentType = response.headers["content-type"];
    const contentDisposition = response.headers["content-disposition"];
    const data = response.data;
    const fileName = contentDisposition.substring(
        contentDisposition.indexOf("filename=") + "filename=".length
    );
    const fileUrl = window.URL.createObjectURL(
        new Blob([data], { type: contentType })
    );
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);

    return data
}

export const exportAudio = async (id, from='question') => {
    const url = from === 'question' ? `/api/questions/${id}/export-audio` : `/api/results/${id}/export-audio`
    const response = await axios.get(
        url,
        {
            responseType: "blob",
        }
    );

    const contentType = response.headers["content-type"];
    const contentDisposition = response.headers["content-disposition"];
    const data = response.data;

    const fileName = contentDisposition.substring(
        contentDisposition.indexOf("filename=") + "filename=".length
    );

    const fileUrl = window.URL.createObjectURL(
        new Blob([data], { type: contentType })
    );
    const fileLink = document.createElement("a");
    fileLink.href = fileUrl;
    fileLink.setAttribute("download", fileName);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);

    return response
}

export const shareSurveyEmailByList = async (emailList, surveyLink, hash, emailCustomization) => {
    return await axios.post("/api/share/send-email-from-list", {
        emailList,
        surveyLink,
        hash,
        emailCustomization
    });
}

export const shareSurveyEmailByCSV = async (csvTable, surveyLink, hash, emailCustomization) => {
    return await axios.post("/api/share/send-email-from-csv", {
        csvTable,
        surveyLink,
        hash,
        emailCustomization
    });
}

export const toggleLikeAnswer = async (answerId, likeValue) => {
    await axios.put(`/api/likes/${answerId}`, {
        likeValue
    });
}