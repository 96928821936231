const state = {
    snackbar: {},
    dialog: false,
    dialogBodyText: '',
    illustration: null,
    error: null
};

const getters = {
    dialogBodyText: state => state.dialogBodyText,
    dialogIllustration: state => state.illustration,
};

const mutations = {
    hideSnackBar: (state) => {
        state.snackbar = { ...state.snackbar, text: '', show: false }
    },
    showSnackBar: (state, snackbar) => {
        state.snackbar = { ...snackbar, show: true }
    },
    closeDialog: (state) => {
        state.dialog = false
        state.dialogBodyText = ''
    },
    openDialog: (state) => {
        state.dialog = true
    },
    setDialogBodyText: (state, text) => {
        state.dialogBodyText = text
    },
};

const actions = {
    openDialog({state, commit }, { bodyText, illustration }) {
        
        state.dialogBodyText = bodyText || ''
        state.illustration = illustration || null
        commit('openDialog')
    },
    closeDialog({ commit }) {
        commit('closeDialog')
        state.dialogBodyText = ''
    },
};

export default {
    state, getters, mutations, actions
}
