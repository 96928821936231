import axios from 'axios'
export default {
    updateUserInfo(user_info) {
        return axios.post('api/users/user-info', {
            use_type: user_info.use_type,
            activity: user_info.activity,
            industry: user_info.industry,
            org_size: user_info.org_size,
            team: user_info.team,
            reference: user_info.reference, 
        })
    },
    async startProbingTrial(surveyId){
        return axios.get(`/api/users/start-probing-trial/${surveyId}`)
    }
}