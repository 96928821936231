import gradient from 'gradient-parser'
function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export function invertColor(hex, bw) {
    if (hex.indexOf('#') === 0) {
      hex = hex.slice(1);
    }

    if(hex.length > 6){
        hex = hex.slice(0, 6);
    }
    
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if(hex.includes("linear-gradient")){
        const parsedGradient = gradient.parse(hex)
        if(parsedGradient && parsedGradient.length && parsedGradient[0].colorStops[0]?.value){
            const value = parsedGradient[0].colorStops[0]?.value
            hex = value.replace('#', '').slice(0, 6)

        }
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    var r = parseInt(hex.slice(0, 2), 16),
        g = parseInt(hex.slice(2, 4), 16),
        b = parseInt(hex.slice(4, 6), 16);
    if (bw) {
        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 186
            ? '#000000'
            : '#FFFFFF';
    }
    // invert color components
    r = (255 - r).toString(16);
    g = (255 - g).toString(16);
    b = (255 - b).toString(16);
    // pad each with zeros and return
    return "#" + padZero(r) + padZero(g) + padZero(b);
}

// Version 4.1
export const pSBC = (p, c0, c1, l) => {
    let r,
      g,
      b,
      P,
      f,
      t,
      h,
      m = Math.round,
      a = typeof c1 == "string";
    if (
      typeof p != "number" ||
      p < -1 ||
      p > 1 ||
      typeof c0 != "string" ||
      (c0[0] != "r" && c0[0] != "#") ||
      (c1 && !a)
    )
      return null;
    (h = c0.length > 9),
      (h = a ? (c1.length > 9 ? true : c1 == "c" ? !h : false) : h),
      (f = pSBC.pSBCr(c0)),
      (P = p < 0),
      (t =
        c1 && c1 != "c"
          ? pSBC.pSBCr(c1)
          : P
          ? { r: 0, g: 0, b: 0, a: -1 }
          : { r: 255, g: 255, b: 255, a: -1 }),
      (p = P ? p * -1 : p),
      (P = 1 - p);
    if (!f || !t) return null;
    if (l)
      (r = m(P * f.r + p * t.r)),
        (g = m(P * f.g + p * t.g)),
        (b = m(P * f.b + p * t.b));
    else
      (r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
        (g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
        (b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
    (a = f.a),
      (t = t.a),
      (f = a >= 0 || t >= 0),
      (a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
    if (h)
      return (
        "rgb" +
        (f ? "a(" : "(") +
        r +
        "," +
        g +
        "," +
        b +
        (f ? "," + m(a * 1000) / 1000 : "") +
        ")"
      );
    else
      return (
        "#" +
        (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
          .toString(16)
          .slice(1, f ? undefined : -2)
      );
  };
  
  pSBC.pSBCr = (d) => {
    const i = parseInt;
    let n = d.length,
      x = {};
    if (n > 9) {
      const [r, g, b, a] = (d = d.split(","));
      n = d.length;
      if (n < 3 || n > 4) return null;
      (x.r = i(r[3] == "a" ? r.slice(5) : r.slice(4))),
        (x.g = i(g)),
        (x.b = i(b)),
        (x.a = a ? parseFloat(a) : -1);
    } else {
      if (n == 8 || n == 6 || n < 4) return null;
      if (n < 6)
        d =
          "#" +
          d[1] +
          d[1] +
          d[2] +
          d[2] +
          d[3] +
          d[3] +
          (n > 4 ? d[4] + d[4] : "");
      d = i(d.slice(1), 16);
      if (n == 9 || n == 5)
        (x.r = (d >> 24) & 255),
          (x.g = (d >> 16) & 255),
          (x.b = (d >> 8) & 255),
          (x.a = Math.round((d & 255) / 0.255) / 1000);
      else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
    }
    return x;
  };