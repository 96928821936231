import MembershipsApi from "../api/MembershipsApi";
import TeamApi from "../api/Teams"

const state = {
    memberships: null,
    invites: null
};

const getters = {
    activeMembership(state, getters, rootState){
        return state.memberships && state.memberships.find(membership => membership.id === rootState.auth.user.active_membership_id)
    },
    memberships: state => state.memberships,
    invites: state => state.invites,
};

const mutations = {
    setActiveMembership(state, activeMembership) {
        state.activeMembership = activeMembership
    },
    setMemberships(state, memberships) {
        state.memberships = memberships
    },
    setInvites(state, invites){
        state.invites = invites
    }
};

const actions = {
    async fetchMemberships({commit}) {
        const res = await MembershipsApi.listMemberships();
        if(res && res.data && res.data.memberships) {
            commit('setMemberships', res.data.memberships.filter(membership=> ['joined', 'blocked'].includes(membership.status)))
            commit('setInvites', res.data.memberships.filter(membership=> membership.status === 'pending'))
        }
        return res;
    },

    async createTeam({dispatch}, data){
        const res = await TeamApi.createTeam(data)
        if(res && res.data && res.data.status === 'created') {
            await dispatch('auth/setUser', null, {root: true})
            await dispatch('fetchMemberships')
        }

        return res
    },
    async inviteToTeam({dispatch}, {teamId, invites}){
        const res = await TeamApi.inviteToTeam(teamId, {invites})
        if(res && res.data && res.data.status === 'invited') {
            await dispatch('fetchMemberships')
            await dispatch('billing/fetchUsage', null, {root: true})
        }
        return res
    },
    async removeMember({dispatch}, {teamId, membershipId}){
        const res = await TeamApi.removeMember(teamId, membershipId)
        if(res && res.data && res.data.status === 'removed') {
            await dispatch('fetchMemberships')
            await dispatch('billing/fetchUsage', null, {root: true})
        }
        return res
    },
    // eslint-disable-next-line
    async resendInvite({}, {teamId, membershipId}){
        const res = await TeamApi.resendInvite(teamId, membershipId)
        return res
    },

    async updateMember({dispatch}, {teamId, membershipId, data}){
        const res = await TeamApi.updateMember(teamId, membershipId, data)
        if(res && res.data && res.data.status === 'updated') {
            await dispatch('fetchMemberships')
        }
        return res
    },

    async updateTeam({dispatch}, {teamId, data}){
        const res = await TeamApi.update(teamId, data)
        if(res && res.data && res.data.status === 'updated') {
            await dispatch('fetchMemberships')
        }
        return res
    },

    async answerInvite({dispatch}, {membershipId, answer}){
        const res = await MembershipsApi.answerInvite(membershipId, {answer})
        if(res && res.status===200) {
            await dispatch('auth/setUser', null, {root: true})
            await dispatch('fetchMemberships') 
        }

        return res
    },

    async removeMembership({dispatch}, membershipId){
        const res = await MembershipsApi.removeMembership(membershipId)
        if(res && res.status===200) {
            await dispatch('auth/setUser', null, {root: true})
            await dispatch('fetchMemberships') 
        }

        return res
    },

    async removeTeam({dispatch}, teamId){
        const res = await TeamApi.destroy(teamId)
        if(res && res.status===200) {
            await dispatch('auth/setUser', null, {root: true})
            await dispatch('fetchMemberships') 
            await dispatch('billing/fetchUsage', null, {root: true})
        }

        return res
    }
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}