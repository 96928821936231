import axios from 'axios'
export default {
    createTeam(data){
        return axios.post('/api/teams', data)
    },
    inviteToTeam(teamId, data){
        return axios.post(`/api/teams/${teamId}/invite`, data)
    },
    removeMember(teamId, membershipId){
        return axios.delete(`/api/teams/${teamId}/members/${membershipId}`)
    },
    resendInvite(teamId, membershipId){
        return axios.get(`/api/teams/${teamId}/members/${membershipId}/resend-invite`)
    },
    updateMember(teamId, membershipId, data){
        return axios.patch(`/api/teams/${teamId}/members/${membershipId}`, data)
    },
    update(teamId, data){
        return axios.patch(`/api/teams/${teamId}`, data)
    },
    destroy(teamId){
        return axios.delete(`/api/teams/${teamId}`)
    }
}