import axios from 'axios'
export default {
    getUsers(page = 1, itemsPerPage = 15, search = ''){
        return axios.get(`/api/admin/users?page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`)
    },
    getUserById(id){      
        return axios.get(`/api/admin/users/${id}`)
    },

    getPricePlans(search = ''){
        if(search){
            return axios.get(`/api/admin/price-plans?search=${search}`)
        }
        return axios.get('/api/admin/price-plans')
    },
    getUserUsage(userId, startDate=null, endDate=null){
        if(startDate && endDate){
            return axios.get(`/api/admin/users/${userId}/usage?startDate=${startDate}&endDate=${endDate}`)
        }
        return axios.get(`/api/admin/users/${userId}/usage`)
    }
}