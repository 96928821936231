const DEFAULT_FORM_STATE = {
    updatedAt: new Date().getTime(),
    metadata: null,
    answers: {},
    dialogues: {},
    history: [0],
    steps: [],
    variables: {},
    loopLists: {},
}

export const getSurveyFromLocalStorage = (surveyId) => {
    return localStorage?.getItem(surveyId) ? JSON.parse(localStorage?.getItem(surveyId)) : null;
}

export const removeSurveyFromLocalStorage = (surveyId) => {
    localStorage?.removeItem(surveyId);
}


export const saveSurveyToLocalStorage = (form, additionalData) => {
    try {
        
        const { 
            survey, 
            variables,
            loopLists,
            steps
        } = form;
    
        const surveyId = survey.id;
    
        let savingPayload = null;
        // if survey already exists we need to use old data and update it with new data
        const existingSurveyData = getSurveyFromLocalStorage(surveyId);
        if(existingSurveyData) {
            savingPayload = {
                ...existingSurveyData,
                ...additionalData,
                steps,
                loopLists,
                variables,
                updatedAt: new Date().getTime(),
            }
        }else{
            savingPayload = {
                ...DEFAULT_FORM_STATE,
                formId: survey.id,
                steps,
                loopLists,
                variables,
                updatedAt: new Date().getTime(),
            }
        }
    
        if(savingPayload){
            localStorage?.setItem(survey.id, JSON.stringify(savingPayload));
        }
    } catch (error) {
        console.error('Unable to save survey to local storage:', error);    
    }
}