import { defaultConditionValue, variableType } from "../configs/questionTypes";
import operators, {IS, IS_NOT} from "../configs/operators";
import {YESNO} from '../configs/questionTypes'
import { uuid } from 'vue-uuid';
import LogicApi from "../api/LogicApi";

const state = {
    actions: {},
    edits: {},
    questions: []
};

const getters = {
    edits: state => state.edits,
    actions: state => state.actions,
    questions: state => state.questions
};

const mutations = {
    resetActions(state){
        state.actions={}
    },
    setActions(state, {questionId, actions}){
        state.actions = {...state.actions, [questionId]: actions}
    },
    setQuestions(state, questions){
        state.questions = questions
    },
    setAllActions(state, actions){
        state.actions = actions
    },
    setEditAction(state, payload){
        state.edits = {...state.edits, [payload.questionId]: payload.action} 
    },
    deleteEditAction(state, questionId){
        delete state.edits[questionId]
        state.edits = {...state.edits}
    }
};

function generateConditionFromQuestion(question){
    const type = variableType(question);
    if(type === 'matrix'){
        return {
            id: uuid.v4(),
            variable: {
                type,
                question_ref: question.id,
                row_id: null,
                col_id: null
            },
            op: null,
            value: null,
            log_op: null
        }
    }

    let value = defaultConditionValue(type, question);
    return {
        id: uuid.v4(),
        variable: {
            type,
            question_ref: question.id
        },
        op: operators[type][0].value,
        value,
        log_op: null
    }
}

function isActionBroken(action, question, questions){

    if(action.type === 'go-to'){
        const toQuestion = questions.find(surveyQuestion => surveyQuestion.id === action.to)

        if(!toQuestion){
            return true
        }

        if(toQuestion && toQuestion.order<question.order){
            return true
        }
    }

    // validate conditions
    for (let index = 0; index < action.conditions.length; index++) {
        const condition =action.conditions[index]
        if(!condition.script){
            if(condition.variable && condition.variable.question_ref ){
                const referenced_question = questions.find(q => q.order <= question.order && q.id === condition.variable.question_ref)
                if(!referenced_question){
                    return true
                }
                // check if selected operator is not boolean
                if(referenced_question.type !== YESNO && [IS, IS_NOT].includes(condition.op)){
                    // check if referenced question has multiple options
                    const ruleType = variableType(referenced_question)
                    if(ruleType==='options'){
                        // if cannot find matching option, that means the condition is broken
                        const matchingOption = referenced_question.multiple_choice_items.find(option=> option.value === condition.value)
                        if(!matchingOption){
                            return true
                        }
                    }
                }
            }else{
                return true
            }
        }
    }
    

    return false
} 

const actions = {
    addNewAction({commit}, {question, override}){
        const action = {
            id: uuid.v4(),
            order: 1,
            type: 'go-to',
            to: null,
            conditions: [
                generateConditionFromQuestion(question)
            ],
            submit_answers: false,
            ...override
        }

        commit('setEditAction', {questionId: question.id, action})

        return action

    },
    addNewConditionToAction({state, dispatch}, question){
        const newCondition = generateConditionFromQuestion(question)
        const action = state.edits[question.id]
        dispatch('updateNewActionCondition', {questionId: question.id, condition: {...action.conditions[action.conditions.length-1], log_op: 'and'} })
        state.edits[question.id].conditions.push(newCondition)
    },
    updateNewAction({commit}, {question, action}){
        commit('setEditAction', {questionId: question.id, action})
    },
    updateNewActionCondition({state, commit}, payload){
        const {questionId} = payload;
        const conditionIndex = state.edits[questionId].conditions.findIndex(condition => condition.id === payload.condition.id)
        const newAction = {...state.edits[questionId]}
        newAction.conditions[conditionIndex] = payload.condition
        commit('setEditAction', {questionId, action: newAction})
    },
    deleteEditAction({commit}, question){
       commit('deleteEditAction', question.id)
    },
    deleteActionCondition({state, commit}, {questionId, condition}){
        const newConditionsList = state.edits[questionId].conditions.filter(con => con.id !== condition.id);
        const newAction = {...state.edits[questionId]}
        newAction.conditions = newConditionsList
        commit('setEditAction', {questionId, action: newAction})
    },
    async saveEditAction({state, commit, dispatch}, {survey, question}){
        const action = state.edits[question.id];
        if(isActionBroken(action, question, state.questions)){
            return commit(
                'showSnackBar',
                {
                    color: "error",
                    timeout: 2000,
                    text: "This action still seems broken. Please fix it before saving."
                },
                {root: true}
            )
        }
        let res = null;

        // if always trigger is selected, remove all conditions
        if(action.always_trigger){
            action.conditions = []
        }

        if(action.created_at){
            res = await LogicApi.updateAction(survey.id, question.id, action.id, action)
        }else{
            res = await LogicApi.addNewAction(survey.id, question.id, action)
        }
        if(res && res.data && res.data.question){
            commit('deleteEditAction', question.id)
            dispatch('setActions', res.data.question)
        }
        return res
    },
    async deleteAction({dispatch}, {surveyId, questionId, actionId}){
        const res = await LogicApi.deleteAction(surveyId, questionId, actionId)
        if(res && res.data && res.data.question){
            dispatch('setActions', res.data.question)
        }
        return res
    },
    async initActionsFromSurvey({dispatch, commit}, surveyId){
        commit('resetActions')
        const res = await LogicApi.listQuestions(surveyId)
        if(res && res.data){
            commit('setQuestions', res.data)
            res.data.forEach(question => {
                if(question.actions && question.actions.length){
                    dispatch('setActions', question)
                }
            });
        }
    },
    setActions({commit, state}, question){
        const validatedActions = question.actions.map(action => ({
            ...action,
            broken: isActionBroken(action, question, state.questions)
        }))
        commit('setActions', {questionId: question.id, actions: validatedActions})
    }
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}