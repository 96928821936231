const state = {
    businessUseCase: '',
    goal: '',
    length: 5,
    useType: 'research_survey',
    result: null
};

const getters = {
    businessUseCase: state => state.businessUseCase,
    goal: state => state.goal,
    length: state => state.length,
    useType: state => state.useType,
    result: state => state.result,
};

const mutations = {
    clear: (state) => {
        state.goal = '';
        state.length = 5;
        state.useType = 'research_survey';
        state.result = null;
    },
    setBusinessUseCase: (state, businessUseCase) => {
        state.businessUseCase = businessUseCase;
    },
    setGoal: (state, goal) => {
        state.goal = goal;
    },
    setLength: (state, length) => {
        state.length = length;
    },
    setUseType: (state, useType) => {
        state.useType = useType;
    },
    setResult: (state, result) => {
        state.result = result;
    },
};

const actions = {
};

export default {
    namespaced: true,
    state, getters, mutations, actions
}
