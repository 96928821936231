import axios from 'axios'
export default {
    createWebhook(surveyId, url) {
        return axios.post(`api/surveys/${surveyId}/webhooks`, {
            url,
        })
    },
    listWebhooks(surveyId){
        return axios.get(`api/surveys/${surveyId}/webhooks`)
    },
    updateWebhook(surveyId, webhookId, data){
        return axios.patch(`api/surveys/${surveyId}/webhooks/${webhookId}`, data)
    },

    deleteWebhook(surveyId, webhookId){
        return axios.delete(`api/surveys/${surveyId}/webhooks/${webhookId}`)
    }
}