
export default [
    {
        path: '/',
        redirect: '/dashboard',
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
        meta: {
            auth: {
                roles: false,
                redirect: '/'
            }
        }
    },
    {
        path: '/verify-email/:email/:token',
        name: 'verify-email',
        component: () => import('@/views/VerifyEmail.vue'),
        meta: {}
    },
    {
        path: '/sign-up',
        name: 'sign-up',
        component: () => import('@/views/SignUp.vue'),
        meta: {
            auth: {
                roles: false,
                redirect: '/'
            }
        }
    },
    {
        path: '/invite-sign-up',
        name: 'invite-sign-up',
        component: () => import('@/views/SignUp.vue'),
        props: { invite: true},
        meta: {
            auth: {
                roles: false,
                redirect: '/',
            }
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('@/views/ForgotPassword.vue'),
        meta: {
            auth: {
                roles: false,
                redirect: '/'
            }
        }
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: () => import('@/views/ResetPassword.vue'),
        meta: {
            auth: {
                roles: false,
                redirect: '/'
            }
        }
    },
    {
        path: '/403',
        name: '403',
        component: () => import('@/views/static/403.vue'),
        meta: {}
    },
    {
        path: '/to/:hash/questions/:questionId',
        name: 'single-question-widget',
        component: () => import('../views/survey/single-question/SingleQuestion.vue'),
        meta: {}
    },
    {
        path: '/to/:hash',
        name: 'survey-demo',
        component: () => import('../views/survey/Survey.vue'),
        meta: {}
    },
    { path: "/404", component: () => import('../views/NotFound.vue') },
    { path: "*", redirect: "/404" },
]
