import VisualizationAPI from '../api/VisualizationApi'

const state = {
    showExportEditorDialog: false,
    answer: null,
    design: {
        reaction: true,
        reactionIcon: 'https://cdn.voiceform.com/media/emojis/107-heart-4.png',
        primaryColor: '#0066FF',
        iconUrl: '',
        captionText: '',
        watermark: true,
        watermarkUrl: 'https://cdn.voiceform.com/media/audio-icons/voiceform_watermark@2x.png',
    },
    visualizations: null
};

const mutations = {
    setVisualizations(state, payload){
        const map = {}
        payload.forEach(item => {
            map[item.id]= item
        })
        state.visualizations = map
    }
};

const getters = {
    getVisualizationsArray(state){
        return state.visualizations && Object.values(state.visualizations)
    }
}

const actions = {
    async listVisualizations({commit}, surveyId) {
        const res = await VisualizationAPI.list(surveyId)
        if(res && res.data && res.data.visualizations){
            commit('setVisualizations', res.data.visualizations)
        }
        return res;
    }
}

export default {
    namespaced: true,
    actions,
    state,
    mutations,
    getters
}