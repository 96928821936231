function sendMessageToParent(data) {
    try {
        // Check if inside an iframe and use standard postMessage
        if (window && window.parent && window.parent !== window) {
            window.parent.postMessage(data, '*');
        }
        
        // Check for React Native WebView
        else if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify(data));
        }
        
        // Check for Android WebView (native Android app)
        else if (window.AndroidWebView) {
            window.AndroidWebView.postMessage(JSON.stringify(data));
        }

        // Check for iOS WebView (native iOS app)
        else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.postMessage) {
            window.webkit.messageHandlers.postMessage.postMessage(JSON.stringify(data));
        }
        
        // Fallback for any other WebView
        else if (window.postMessage) {
            window.postMessage(data, '*');
        }
    } catch (error) {
        console.error('Cannot send message', error);
    }
}

export default sendMessageToParent;