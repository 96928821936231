import {client} from '../utils/sharedLangchainClient';
import { uuid } from "vue-uuid";

const defaultState = {
    messages: [],
    currentThreadId: null,
    currentThread: null,
    assistantId: null,
    threads: [],
    targetSurveyId: null,
    fetchingThreads: false,
    chainInProgress: false,
    userMessage: '',
};

const state = {...defaultState};

const getters = {
    messages: state => {
        return state.messages
    },
    currentThreadId: state => {
        return state.currentThreadId
    },
    currentThread: state => {
        return state.currentThread
    },
    threads: state => {
        return state.threads
    },
    assistantId: state => {
        return state.assistantId
    },
    userMessage: state => {
        return state.userMessage
    },
    chatInProgress: state => {
        return state.chainInProgress
    },
};

const mutations = {
    setMessages: (state, messages) => {
        state.messages = messages
    },
    addMessage: (state, message) => {
        state.messages = [...state.messages, message]
    },
    setCurrentThreadId: (state, threadId) => {
        state.currentThreadId = threadId
    },
    setCurrentThread: (state, thread) => {
        state.currentThread = thread
    },
    setThreads: (state, threads) => {
        state.threads = threads
    },
    setAssistantId: (state, assistantId) => {
        state.assistantId = assistantId
    },
    setTargetSurveyId: (state, surveyId) => {
        state.targetSurveyId = surveyId
    },
    setChainInProgress: (state, value) => {
        state.chainInProgress = value
    },
    setUserMessage: (state, message) => {
        state.userMessage = message
    },
};

const actions = {
    async getAssistants({commit}){
        const assistants = await client.assistants.search({
            offset: 0,
            limit: 10,
        });

        const assistant = assistants[0];

        commit('setAssistantId', assistant.assistant_id)
    },
    async getThreads({state, commit, rootState}){ 
        try {
            state.fetchingThreads = true;
            const threads = await client.threads.search({
                metadata: {
                    user_id: rootState.auth.user.id,
                    survey_id: state.targetSurveyId
                },
                limit: 50,
            });
            const threadsWithValues = threads.filter(thread => !!thread.values);
            commit('setThreads', threadsWithValues);
        } catch (error) {
          console.error('Error fetching threads:', error);
        } finally {
            state.fetchingThreads = false;
        }
    },

    async updateThread({state, commit}, {threadId, payload}){
        if(!threadId || !payload){
            return;
        }

        const thread = await client.threads.update(threadId, payload);

        // update thread if its current
        // update thread in threads

        if(state.currentThreadId === threadId){
            commit('setCurrentThread', thread);
        }

        const threadIndex = state.threads.findIndex(thread => thread.thread_id === threadId);

        if(threadIndex > -1){
            const threads = [...state.threads];
            threads[threadIndex] = thread;
            commit('setThreads', threads);
        }
    },

    async deleteThread({state, commit, dispatch}, threadId){
        if(!threadId){
            return;
        }

        await client.threads.delete(threadId);
        const threads = state.threads.filter(thread => thread.thread_id !== threadId);
        commit('setThreads', threads);

        if(state.currentThreadId === threadId){
            // select next or create new thread
            if(threads.length){
                dispatch('switchThread', threads[0].thread_id);
            }else{
                dispatch('createNewThread');
            }
        }
    },

    async initChatForSurveyId({ dispatch, state, commit}, surveyId){
        if(!state.assistantId){
            await dispatch('getAssistants');
        }

        // check if we use the same survey. If not, we need to reset to default state and lo
        if(!state.targetSurveyId || surveyId !== state.targetSurveyId){
            // resetting state
            state = {
                ...defaultState, 
                assistantId: state.assistantId,
            };

            commit('setTargetSurveyId', surveyId);
            await dispatch('getThreads');
            await dispatch('loadLastThreadOrCreate');
        }
    },

    async createNewThread({commit, state, rootState}){
        const thread = await client.threads.create({
            metadata: {
                user_id: rootState.auth.user.id,
                survey_id: state.targetSurveyId
            }
        });

        commit('setThreads', [thread, ...state.threads]);
        commit('setMessages', []);
        commit('setCurrentThreadId', thread.thread_id);
        commit('setCurrentThread', thread);
    },

    // eslint-disable-next-line no-unused-vars
    async getThreadById({commit},threadId){
        const thread = await client.threads.get(threadId);
        return thread;
    },

    async loadMessagesFromCurrentThread({commit, state, dispatch}){
        if(!state.currentThreadId){
            return;
        }

        const thread = await dispatch('getThreadById', state.currentThreadId);
        const threadValues = thread.values
        if(threadValues && threadValues.messages){
            commit('setMessages', threadValues.messages);
        }
    },

    async loadLastThreadOrCreate({ dispatch, state, commit}){
        if(!state.threads || !state.threads.length){
            await dispatch('createNewThread');
        }else{
            commit('setCurrentThreadId', state.threads[0].thread_id);
            commit('setCurrentThread', state.threads[0]);
            dispatch('loadMessagesFromCurrentThread');
        }
    },
    async switchThread({commit, dispatch}, thread){
        if(state.currentThreadId === thread.thread_id){
            return;
        }
        commit('setCurrentThreadId', thread.thread_id);
        commit('setCurrentThread', thread);
        commit('setMessages', []);
        await dispatch('loadMessagesFromCurrentThread');
    },

    async cancelRun({state, commit}){
        if(state.currentThreadId){
            const runs = await client.runs.list(state.currentThreadId, {limit: 10});
            
            if(runs && runs.length){
                // cancel all runs with status "pending" without waiting
                runs.forEach(async run => {
                    if(run.status === "pending"){
                        await client.runs.cancel(state.currentThreadId, run.run_id);
                    }
                });
            }
            commit('setChainInProgress', false);
        }
    },

    async sendMessageAndStartStreamingRun({state, commit}, userMessage){
        if(!state.currentThreadId || !userMessage){
            return;
        }

        const humanMessage = {
            role: "human",
            content: userMessage
        }
        // we are create
        commit('addMessage', {
            type: humanMessage.role,
            content: humanMessage.content,
            id: uuid.v4(),
            additional_kwargs: {},
            example: false,
            name: null,
            response_metadata: {},
        });

        const input = {
            messages: [humanMessage]
        };

        if(!state.chainInProgress){
            commit('setChainInProgress', true);
        }


        // prepare configurations
        const configurable = {}

        if(state.targetSurveyId){
            configurable.survey_id = state.targetSurveyId;
        }

        if(state.currentThread.metadata.data_scope){
            // TODO: modify this after introducing custom filters
            configurable.question_id = state.currentThread.metadata.data_scope;
        }

        const streamResponse = client.runs.stream(
            state.currentThreadId,
            state.assistantId,
            {
                input: input,
                streamMode: "events",
                config: {
                    configurable
                },
            },
        );

        

        // Stream the response
        for await (const chunk of streamResponse) {
            // mo matter what we want to set the chain in progress as soon as possible
            if(!state.chainInProgress){
                commit('setChainInProgress', true);
            }
            if(chunk.data.event === "on_chain_end" && !(chunk?.data?.metadata?.langgraph_node)){
                // TODO: handle error if exists
                commit('setChainInProgress', false);
            }

            // handle error
            if(chunk.event==="error"){

                let displayErrorMessage = true;
                if(chunk.data.error === 'UserInterrupt'){
                    displayErrorMessage = false;
                }

                if(displayErrorMessage){
                    commit('addMessage', {
                        type: "ai",
                        content: "Looks like there was an error. Please try again or create a new thread.",
                        id: uuid.v4(),
                        additional_kwargs: {},
                        example: false,
                        name: null,
                        response_metadata: {},
                    });
                }
                commit('setChainInProgress', false);
            }

            if (chunk.data.event === "on_chat_model_stream") {
                const message = chunk.data.data.chunk;
                // const toolCallChunk = message.tool_call_chunks?.[0];
                // console.log("toolCallChunk", toolCallChunk);
                // Check if the message already exists
                const existingMessage = state.messages.find(msg => msg.id === message.id);

                if (existingMessage) {
                    // Append new content to the existing message
                    existingMessage.content += message.content;
                } else {
                    commit("addMessage", message);
                }
            }
        }
    }






};

export default {
    namespaced: true,
    state, getters, mutations, actions
}
