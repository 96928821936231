import axios from 'axios'
export default {
    store(answerId, formData){
        return axios.post(`/api/visualizations/${answerId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    },
    list(surveyId){
        return axios.get(`/api/visualizations/${surveyId}`);
    }
}