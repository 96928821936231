import Vue from 'vue'
import Vuex from 'vuex'
import auth from "./auth";
import site from "./site";
import survey from "./survey";
import sidebar from "./sidebar";
import payment from "./payment";
import audio from "./audio";
import form from './form';
import billing from './billing'
import userInfoForm from "./userInfoForm";
import admin from './admin';
import teams from './teams';
import logic from './logic';
import visualization from './visualization';
import aiFormGeneration from './aiFormGeneration';
import chatAgent from './chatAgent';
import { removeSurveyFromLocalStorage } from '../utils/formLocalStorage';


Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        auth,
        site,
        survey,
        sidebar,
        payment,
        audio,
        form,
        billing,
        userInfoForm,
        admin,
        teams,
        logic,
        visualization,
        aiFormGeneration,
        chatAgent,
    }
});

store.subscribe((mutation, state) => {
    const { type } = mutation;
    const { form } = state;
    if (type === 'form/setSubmission') {
        removeSurveyFromLocalStorage(form.survey.id);
    }
});

export default store;